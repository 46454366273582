export const subjectAlarm = {
  CASE_SETTING: 'caseSetting',
  STANDARD_SETTING: 'standardSetting',
  OPERATING_STATUS_SETTING: 'operatingStatusSetting',
  ALARM_LIST: 'alarmList',
};

export const SUB = {
  SENSOR_TABLE: 0,
  CONTROL_TABLE: 1,
};
