const routePath = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  MAIN: '/main',
  ACCOUNT_MANAGE: '/account_manage',
  NOT_FOUND: '/not_found',
  ALARM_CHECK: '/alarm-check',
  ALARM_RECEIVE: '/alarm-receive',
};

export default routePath;
