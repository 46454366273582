import dayjs from 'dayjs';
import { INIT_MAPPING_OPTION } from 'services/init-data';
import { alarmManage, sensorAlarmRules } from 'types/alarm-T';
import { childT, domainT, sensor } from 'types/dashboard-T';
import { controller, mappingOption } from 'types/remote-T';
import { mappingList } from 'types/sensor-T';
import { v4 as uuid } from 'uuid';
import cloud from 'assets/IEMS icon/wearherIcons/cloud-01.svg';
import cloudRain from 'assets/IEMS icon/wearherIcons/cloud-raining-05.svg';
import cloudSnow from 'assets/IEMS icon/wearherIcons/cloud-snowing-01.svg';
import sunny from 'assets/IEMS icon/wearherIcons/sunny.svg';
import sunnyBg from 'assets/IEMS icon/wearherIcons/sunny-bg.svg';
import cloudBg from 'assets/IEMS icon/wearherIcons/cloud-bg.svg';
import rainBg from 'assets/IEMS icon/wearherIcons/rain-bg.svg';
import snowBg from 'assets/IEMS icon/wearherIcons/snow-bg.svg';

import store from 'store/configureStore';
import { setErrorData } from 'store/modules/error';
import { setConfirmData } from 'store/modules/confirm';
import * as _ from 'lodash';
import { buttonData } from 'components/DatePicker/DatePicker';
import { statusColors } from 'constants/colors';
import { setWeatherBg } from 'store/modules/theme';
/* eslint-disable @typescript-eslint/no-explicit-any */

export const customError = (message: string) => {
  return {
    success: false,
    error: {
      code: '',
      message,
    },
  };
};

export const customConfirm = (message: string) => {
  return {
    isConfirm: true,
    message,
  };
};

export const tablePageInfo = {
  ACCOUNT_MANAGE: 0,
  CONTROLLER_LOG: 1,
};

export const switchKeyData = (pageNum: number) => {
  switch (pageNum) {
    case 0:
      return cvtAccountData;
    case 1:
      return cvtControllerData;
    default:
      return (rowData: any, key: string) => rowData[key];
  }
};

export const cvtAccountData = (rowData: any, key: string) => {
  const val = rowData[key];

  switch (key) {
    case 'admin':
      return val ? 'master' : 'user';
    default:
      return val;
  }
};

export const cvtControllerData = (rowData: any, key: string) => {
  const val = rowData[key];
  if (key === 'createdAt') {
    return val.replaceAll('T', ' ');
  }
  if (key === 'log' && Array.isArray(val)) {
    return val
      .map((item) => `${item.name} ${item.value} ${item.unit}`)
      .join(', ');
  }
  return val;
};

export const convertDate = (timestamp: number) => {
  const plusKstTime = 1000 * 60 * 60 * 9;
  return new Date(timestamp + plusKstTime).toISOString().slice(0, 16);
};

export const convertGraphToFixed = (data: string) => {
  return String(data).split('.')[1]?.length > 2
    ? Number(data).toFixed(2)
    : data;
};

export const deleteBoxId = (loopData: mappingList[]) => {
  loopData.forEach((item: mappingList) => {
    if (item.id !== undefined) {
      delete item.id;
    }

    if (item.children && item.children.length > 0) {
      deleteBoxId(item.children);
    }
  });

  return loopData;
};

export const addBoxId = (loopData: mappingList[]) => {
  loopData.forEach((item: mappingList) => {
    if (item.id === undefined) {
      item.id = uuid();
    }

    if (item.children && item.children.length > 0) {
      addBoxId(item.children);
    }
  });

  return loopData;
};

// export const findCheckedResult = (loopData: mappingList[]) => {
//   let hasCheckedResult = false;
//   loopData.forEach((item: mappingList) => {
//     if (item.checkedResult) {
//       hasCheckedResult = true;
//     }
//     if (item.child && item.child.length > 0) {
//       findCheckedResult(item.child);
//     }
//   });
//   return hasCheckedResult;
// };

export const checkingResultBox = (
  loopData: mappingList[],
  boxData: mappingList,
) => {
  loopData.forEach((item: mappingList) => {
    if (item.id === boxData.id) {
      item.checkedResult = !item.checkedResult;
    } else {
      item.checkedResult = false;
    }
    if (item.children && item.children.length > 0) {
      checkingResultBox(item.children, boxData);
    }
  });
};

export const deleteMappingBox = (
  loopData: mappingList[],
  boxData: mappingList,
) => {
  loopData.forEach((item: mappingList, loopIdx: number) => {
    if (item.id === boxData.id) {
      loopData = loopData.splice(loopIdx, 1);
    }
    if (item.children && item.children.length > 0) {
      deleteMappingBox(item.children, boxData);
    }
  });
};

export const findHasDashboardNames = (loopData: any[], setList: any) => {
  for (const item of loopData) {
    if (item?.dashboard && item.dashboard.id > 0) {
      const dashboardInfo: any = item.dashboard;

      setList((prev: any) => [
        ...prev,
        {
          domainName: item.name,
          name: dashboardInfo.name || '미지정',
          id: dashboardInfo.id,
          dashboard: item.dashboard,
          children: item.children,
        },
      ]);
    }

    if (item.children.length > 0) {
      findHasDashboardNames(item.children, setList);
    }
  }
};

export const findHasDashboardIds = (loopData: childT[], setList: any) => {
  for (const item of loopData) {
    if (item?.dashboard && item.dashboard.name) {
      const dashboardInfo: any = item.dashboard;
      setList((prev: any) => [
        ...prev,
        {
          domainName: item.name,
          name: dashboardInfo.name,
          id: dashboardInfo.id,
        },
      ]);
    }

    if (item.children.length > 0) {
      findHasDashboardIds(item.children, setList);
    }
  }
};

export const findFirstDashboard = (loopData: childT[]): childT | undefined => {
  for (const item of loopData) {
    if (item?.dashboard) {
      return item;
    }

    if (item.children.length > 0) {
      const res = findFirstDashboard(item.children);
      if (res) {
        return res;
      }
    }
  }

  return undefined;

  // const loopRes = loopData.find((item: childT) => {
  //   if (item?.dashboard) {
  //     console.log(item);
  //     return item;
  //   }

  //   if (item.childList.length > 0) {
  //     const res = findFirstDashboard(item.childList);
  //     console.log(res);
  //     if (res) {
  //       return res;
  //     }
  //   }
  // });
  // console.log(loopRes);
  // return loopRes;
  // TODO: 재귀 구현
};

export const cvtEnteredId = (id: number) => {
  const stringId = String(id);
  if (stringId.length < 8) {
    const zeroLen = 8 - stringId.length;
    return '0'.repeat(zeroLen) + stringId;
  }
  return stringId;
};

export const convertHideData = (data: any, key: string) => {
  const val = data[key];
  switch (key) {
    case 'id':
      return cvtEnteredId(val);
    default:
      return val;
  }
};

export const cvtServerError = (num: number) => {
  switch (num) {
    case 400:
      return `클라이언트의 요청이 올바르지 않습니다.`;
    case 401:
      return `로그인 또는 인증이 필요합니다.`;
    case 403:
      return `해당 리소스에 접근 권한이 없습니다.`;
    case 404:
      return `페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.`;
    case 500:
      return `서버에서 예상치 못한 오류가 발생하였습니다.\n 다시 시도하거나 서버 관리자에게 문의해주세요.`;
    case 502:
      return `서버에서 요청을 처리할 수 없습니다.`;
    case 503:
      return `서버에서 오류가 발생하였습니다.\n 다시 시도해주세요.`;
    default:
      return `예상치 못한 오류가 발생하였습니다.\n 관리자에게 문의해주세요.`;
  }
};

export const cvtReceiveSensor = (timeStr: string) => {
  const getTime = new Date(timeStr).getTime();
  const nowTime = new Date().getTime();
  const diffSecTime = (nowTime - getTime) / 1000;

  if (diffSecTime < 60) {
    return `${Math.round(diffSecTime)}초 전`;
  }

  if (diffSecTime < 3600) {
    return `${Math.round(diffSecTime / 60)}분 전`;
  }

  if (diffSecTime < 86400) {
    return `${Math.round(diffSecTime / 3600)}시간 전`;
  }

  if (diffSecTime >= 86400) {
    return `${Math.round(diffSecTime / 86400)}일 전`;
  }
};

export const remoteMakeParamsOption = (data: controller, btnType: string) => {
  // option 추가 로직
  const regex = /@erix-param\d+/g;
  const filteredParams = data.code.match(regex);
  const filteredOriginParams = data.options.filter(
    (item: mappingOption) => filteredParams?.includes(item.key),
  );
  // 기존 키와 새로운 키를 합치는 로직
  const hasKeyOptions = filteredOriginParams.map(
    (item: mappingOption) => item.key,
  );
  const newKeys = filteredParams?.filter(
    (key: string) => !hasKeyOptions.includes(key),
  );

  if (newKeys && newKeys.length > 0) {
    const addNewOptions = newKeys.map((key: string) => ({
      ...INIT_MAPPING_OPTION,
      key,
      type: btnType,
    }));
    const concatParams = [...filteredOriginParams, ...addNewOptions];
    // 정렬
    const indexKeyMap = filteredParams?.reduce<{ [key: string]: number }>(
      (acc, cur, idx) => {
        acc[cur] = idx;
        return acc;
      },
      {},
    );
    const sortOptions = concatParams.sort(
      (a, b) => indexKeyMap![a.key] - indexKeyMap![b.key],
    );
    data.options = sortOptions;

    return data.options;
  } else {
    // 새로운 키가 없을 때 없어진 키 항목 없애기
    return filteredOriginParams;
  }
};

export const cvtControllerOptionsVal = (data: mappingOption[]) => {
  return data.map((item) => {
    if (item.type === 'NUMBER') {
      return { ...item, value: parseInt(item.value) };
    }

    if (item.type === 'RANGE') {
      return { ...item, value: parseInt(item.value) };
    }

    if (item.type === 'BOOLEAN') {
      return { ...item, value: parseInt(item.value) };
    }
  });
};

export const cvtSignalBoolType = (val: string) => {
  return val === 'true';
};

export const sortFrontHideSensors = (data: sensor[]) => {
  return [...data].sort((a, b) => {
    if (a.hide === true && b.hide !== true) {
      return -1; // a가 앞으로 오도록
    } else if (a.hide !== true && b.hide === true) {
      return 1; // b가 앞으로 오도록
    }
    return 0; // 순서 변경 없음
  });
};

export const isIncludesEmpty = (data: any) => {
  if (Array.isArray(data)) {
    const empty = (ele: any) => ele.length === 0;

    if (data.some(empty)) {
      return true;
    }
    return false;
  }
};

export const findPutIds = (originData: any, curData: any) => {
  const cvtIds: number[] = [];
  const hasIdsArrData = curData.filter((curItem: any) => curItem.id > 0);

  hasIdsArrData.forEach((item: any) => {
    const findData = originData.find((el: any) => el.id === item.id);
    if (findData) {
      const keys = Object.keys(findData);
      keys.forEach((key: string) => {
        if (findData[key] !== item[key]) {
          if (!cvtIds.includes(item.id)) {
            cvtIds.push(item.id);
          }
        }
      });
    }
  });

  return cvtIds;
};

export const convertArrayToObject = (
  array: [number | string, number | string][],
) => {
  const resultObject = array.reduce((obj: any, item, index) => {
    obj[index] = item;
    return obj;
  }, {});
  return resultObject;
};

export const sortSubArray = (arrData: any) => {
  return arrData.map((item: any) => item.sort((a: any, b: any) => a - b));
};

export const findEmptyString = (rangeData: [string, string][]) => {
  let isEmpty = false;

  for (const subArray of rangeData) {
    for (const item of subArray) {
      if (item === '') {
        isEmpty = true;
        break;
      }
    }
    if (isEmpty) {
      break;
    }
  }
  return isEmpty;
};

export const cvtalarmRules = (tableData: sensor[]) => {
  const filteredData = tableData.filter(
    (item) => !!Object.keys(item).find((key) => key.includes('caseName')),
  );
  const cvtData = filteredData.map((item) => {
    const keys = Object.keys(item);
    const alarmCases = keys.filter((key) => key.includes('caseName'));
    return alarmCases.map((key) => {
      return {
        sensorId: item.id,
        alarmCaseId: Number(key.slice(8)),
        ranges: item[key].map((itemVal: any) => ({
          lower: itemVal[0],
          upper: itemVal[1],
        })),
      };
    });
  });

  return cvtData.flat();
};

export const cvtInitAlarmRules = (
  tableData: sensor[],
  rulesData: sensorAlarmRules[],
) => {
  return tableData.map((data) => {
    rulesData.forEach((ruleData: sensorAlarmRules) => {
      const isTargetSensorId = data.id === ruleData.sensorId;
      if (isTargetSensorId) {
        data = {
          ...data,
          [`caseName${ruleData.alarmCaseId}`]: [
            ...ruleData.ranges.map((el: any) => [el.lower, el.upper]),
          ],
        };
      }
    });
    return data;
  });
};

export const cvtAlarmType = (type: string) => {
  switch (type) {
    case 'SENSOR':
      return '센서 알람';
    default:
      return '';
  }
};

export const cvtAlarmStatus = (status: string) => {
  switch (status) {
    case 'CHECKED':
      return '처리 완료';
    case 'RECEIVED':
      return '접수';
    case 'OCCURRED':
      return '요청';
    default:
      return '';
  }
};

export const cvtNxNy = (lat: number, lng: number) => {
  const RE = 6371.00877; // 지구 반경(km)
  const GRID = 5.0; // 격자 간격(km)
  const SLAT1 = 30.0; // 표준 위도 1(degree)
  const SLAT2 = 60.0; // 표준 위도 2(degree)
  const OLON = 126.0; // 기준점 경도(degree)
  const OLAT = 38.0; // 기준점 위도(degree)
  const XO = 43; // 기준점 X좌표(GRID)
  const YO = 136; // 기준점 Y좌표(GRID)

  const DEGRAD = Math.PI / 180.0;
  const RADDEG = 180.0 / Math.PI;

  const re = RE / GRID;
  const slat1 = SLAT1 * DEGRAD;
  const slat2 = SLAT2 * DEGRAD;
  const olon = OLON * DEGRAD;
  const olat = OLAT * DEGRAD;

  let sn =
    Math.tan(Math.PI * 0.25 + slat2 * 0.5) /
    Math.tan(Math.PI * 0.25 + slat1 * 0.5);
  sn = Math.log(Math.cos(slat1) / Math.cos(slat2)) / Math.log(sn);
  let sf = Math.tan(Math.PI * 0.25 + slat1 * 0.5);
  sf = (Math.pow(sf, sn) * Math.cos(slat1)) / sn;
  let ro = Math.tan(Math.PI * 0.25 + olat * 0.5);
  ro = (re * sf) / Math.pow(ro, sn);
  const result = { nx: '', ny: '' };
  let ra = Math.tan(Math.PI * 0.25 + lat * DEGRAD * 0.5);
  ra = (re * sf) / Math.pow(ra, sn);
  let theta = lng * DEGRAD - olon;
  if (theta > Math.PI) theta -= 2.0 * Math.PI;
  if (theta < -Math.PI) theta += 2.0 * Math.PI;
  theta *= sn;
  result.nx = String(Math.floor(ra * Math.sin(theta) + XO + 0.5));
  result.ny = String(Math.floor(ro - ra * Math.cos(theta) + YO + 0.5));

  return result;
};

export const weatherBaseTime = (isLong: boolean) => {
  const nowDate = dayjs();

  if (!isLong) {
    return nowDate.minute() < 45
      ? nowDate.subtract(1, 'hour').format('HH00')
      : nowDate.format('HH00');
  }

  const hour = nowDate.hour();
  let baseHour;
  if (hour >= 23) {
    baseHour = '2300';
  } else if (hour >= 20) {
    baseHour = '2000';
  } else if (hour >= 17) {
    baseHour = '1700';
  } else if (hour >= 14) {
    baseHour = '1400';
  } else if (hour >= 11) {
    baseHour = '1100';
  } else if (hour >= 8) {
    baseHour = '0800';
  } else if (hour >= 5) {
    baseHour = '0500';
  } else {
    baseHour = '0200';
  }
  return baseHour;
};

export const weatherColors = {
  sunny: '#EC7263',
  cloud: '#398EE8',
  rain: '#504B8B',
  snow: '#1DACF0',
};

const bottomWeatherColors = {
  sunny: '#D65747',
  cloud: '#2B62AC',
  rain: '#4961C5',
  snow: '#1386DE',
};

export const weatherImg = (pty: number, sky: number) => {
  let text = '없음';
  let img = sunny;
  let bottomBg = bottomWeatherColors.sunny;
  let topImgBg;
  // store는 기상청 날씨 패널 background-color
  if (pty === 0) {
    switch (sky) {
      case 1:
        text = '맑음';
        img = sunny;
        topImgBg = sunnyBg;
        bottomBg = bottomWeatherColors.sunny;
        store.dispatch(setWeatherBg(weatherColors.sunny));
        break;
      case 3:
        text = '구름 많음';
        img = cloud;
        topImgBg = cloudBg;
        bottomBg = bottomWeatherColors.cloud;
        store.dispatch(setWeatherBg(weatherColors.cloud));
        break;
      case 4:
        text = '흐림';
        img = cloud;
        topImgBg = cloudBg;
        bottomBg = bottomWeatherColors.cloud;
        store.dispatch(setWeatherBg(weatherColors.cloud));
        break;
      default:
        text = '없음';
        img = cloud;
        break;
    }
    return { text, img, bottomBg, topImgBg };
  }

  switch (pty) {
    case 1:
      text = '비';
      img = cloudRain;
      topImgBg = rainBg;
      bottomBg = bottomWeatherColors.rain;
      store.dispatch(setWeatherBg(weatherColors.rain));
      break;
    case 2:
      text = '비/눈';
      img = cloudRain;
      topImgBg = snowBg;
      bottomBg = bottomWeatherColors.snow;
      store.dispatch(setWeatherBg(weatherColors.snow));
      break;
    case 3:
      text = '눈';
      img = cloudSnow;
      topImgBg = snowBg;
      bottomBg = bottomWeatherColors.snow;
      store.dispatch(setWeatherBg(weatherColors.snow));
      break;
    case 4:
      text = '소나기';
      img = cloudRain;
      topImgBg = rainBg;
      bottomBg = bottomWeatherColors.rain;
      store.dispatch(setWeatherBg(weatherColors.rain));
      break;
    case 5:
      text = '빗방울';
      img = cloudRain;
      topImgBg = rainBg;
      bottomBg = bottomWeatherColors.rain;
      store.dispatch(setWeatherBg(weatherColors.rain));
      break;
    case 6:
      text = '빗방울/눈날림';
      img = cloudSnow;
      topImgBg = snowBg;
      bottomBg = bottomWeatherColors.snow;
      store.dispatch(setWeatherBg(weatherColors.snow));
      break;
    case 7:
      text = '눈날림';
      img = cloudSnow;
      topImgBg = snowBg;
      bottomBg = bottomWeatherColors.snow;
      store.dispatch(setWeatherBg(weatherColors.snow));
      break;
    default:
      break;
  }

  return { text, img, bottomBg, topImgBg };
};

export const getWindDirection = (degree: number) => {
  if (degree >= 337.5 || degree < 22.5) return '북';
  if (degree >= 22.5 && degree < 67.5) return '북동';
  if (degree >= 67.5 && degree < 112.5) return '동';
  if (degree >= 112.5 && degree < 157.5) return '남동';
  if (degree >= 157.5 && degree < 202.5) return '남';
  if (degree >= 202.5 && degree < 247.5) return '남서';
  if (degree >= 247.5 && degree < 292.5) return '서';
  if (degree >= 292.5 && degree < 337.5) return '북서';
  return '알 수 없음';
};

export const customErrorMsg = (msg: string) => {
  store.dispatch(setErrorData(customError(msg)));
};

export const customConfirmMsg = (msg: string) => {
  store.dispatch(setConfirmData(customConfirm(msg)));
};

export const isEnteredPositiveNumber = (data: string) => {
  const isPositive = /^\d*$/;
  if (data === '' || isPositive.test(data)) {
    return true;
  }
  return false;
};

export const downloadSensorFile = (data: any) => {
  const copyMappingList = deleteBoxId(_.cloneDeep(data));
  const blob = new Blob([JSON.stringify(copyMappingList)], {
    type: 'application/json',
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'unregistedFile.json';
  a.click();
  URL.revokeObjectURL(url);
};

export const uploadSensorFile = (file: any) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('파일을 읽는 중 오류가 발생하였습니다.'));
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      try {
        const jsonData = addBoxId(JSON.parse(e.target?.result as string));
        console.log(jsonData);
        resolve(jsonData);
      } catch (e: any) {
        reject(
          new Error('파일 업로드에 실패하였습니다. 다시 한 번 확인해주세요.'),
        );
      }
    };
    reader.onerror = () => {
      reject(new Error('파일을 읽는 중 오류가 발생하였습니다.'));
    };
    reader.readAsText(file);
  });
};

const createImage = (url: any) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // cors
    image.src = url;
  });

export default async function getCroppedImg(imageSrc: any, pixelCrop: any) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx: any = canvas.getContext('2d');

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        customErrorMsg('이미지 편집 중, 오류가 발생하였습니다.');
        reject();
        return;
      }
      const file = new File([blob], 'cropped.webp', { type: 'image/webp' });
      resolve({ file });
    }, 'image/webp');
  });
}

export const readFile = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => {
      customErrorMsg('파일을 읽을 수 없습니다. 다시 확인해주세요.');
      reject();
    };
    reader.readAsDataURL(file);
  });
};

export const convertHexToRGB = (hex: string) => {
  const r = parseInt(hex.slice(2, 4), 16);
  const g = parseInt(hex.slice(4, 6), 16);
  const b = parseInt(hex.slice(6, 8), 16);

  return `${r}, ${g}, ${b}`;
};

export const getSensorNameById = (data: any, id: number) => {
  const sensor = data?.sensors?.find((item: any) => item.id === id);
  return sensor ? sensor.name : '';
};

export const setAlarmRange = (data: { lower: string; upper: string }[]) => {
  return data
    ?.map((range: { lower: string; upper: string }) => {
      const lower =
        parseFloat(range.lower) === -Infinity ? '-∞' : parseFloat(range.lower);
      const upper =
        parseFloat(range.upper) === Infinity ? '∞' : parseFloat(range.upper);
      return `${lower}~${upper}`;
    })
    .join(', ');
};

export const calPressBtn = (start: number, end: number) => {
  const ONE_DAY_MS = 86400000;
  const diffDateNum = (end - start) / ONE_DAY_MS;
  return buttonData.find((el: number) => diffDateNum === el);
};

export const cvtArrFlip = (data: string) => {
  return data.split('');
};

export const filterValidMapDomains = (domains: any[]) => {
  const result: any[] = [];

  const traverse = (nodes: any[]) => {
    nodes.forEach((node) => {
      // dashboard가 존재하고 latitude와 longitude가 0이 아닌 경우
      if (
        node.dashboard &&
        node.dashboard.latitude !== 0 &&
        node.dashboard.longitude !== 0
      ) {
        result.push(node.dashboard);
      }

      // children이 존재하면 재귀적으로 탐색
      if (node.children && node.children.length > 0) {
        traverse(node.children);
      }
    });
  };

  traverse(domains);

  return result;
};

export const isPressForRange = (
  startDate: number,
  endDate: number,
  type: string,
): boolean => {
  const now = new Date(); // 현재 날짜
  const start = new Date(startDate); // 시작 날짜
  const end = new Date(endDate); // 종료 날짜

  if (type === 'thisYear') {
    const currentYear = now.getFullYear();

    return (
      start.getFullYear() === currentYear &&
      end.getFullYear() === currentYear &&
      start.getMonth() === 0 &&
      end.getMonth() === 11
    );
  }

  if (type === 'thisMonth') {
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();

    const lastDay = new Date(currentYear, currentMonth + 1, 0).getDate();

    return (
      start.getFullYear() === currentYear &&
      end.getFullYear() === currentYear &&
      start.getMonth() === currentMonth &&
      end.getMonth() === currentMonth &&
      start.getDate() === 1 &&
      end.getDate() === lastDay
    );
  }

  if (type === 'thisWeek') {
    const startOfWeek = new Date(startDate);
    const endOfWeek = new Date(endDate);

    // 이번 주의 월요일과 일요일 계산
    const now = new Date();
    const monday = new Date(now);
    monday.setDate(now.getDate() - now.getDay() + 1);
    monday.setHours(0, 0, 0, 0); // 월요일 00:00:00

    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);
    sunday.setHours(23, 59, 59, 999); // 일요일 23:59:59.999

    // startOfWeek와 endOfWeek가 현재 주의 월요일~일요일과 같은지 확인
    return (
      startOfWeek.getTime() === monday.getTime() &&
      endOfWeek.getTime() === sunday.getTime()
    );
  }

  return false;
};

export const sortedSensors = (data: sensor[]) => {
  const booleanFunc = (rule: any) => {
    return Object.keys(rule).length > 0;
  };
  return data.sort((a, b) => {
    // matchAlarmRule이 있는 경우를 우선 정렬
    if (booleanFunc(a.matchAlarmRule) && !booleanFunc(b.matchAlarmRule))
      return -1;
    if (!booleanFunc(a.matchAlarmRule) && booleanFunc(b.matchAlarmRule))
      return 1;
    return 0; // 그 외는 순서 유지
  });
};

export const resetStartDateTime = (timestamp: number) => {
  const date = new Date(timestamp);
  date.setDate(date.getDate() + 1);
  date.setHours(0, 0, 0, 0); // 시, 분, 초, 밀리초를 0으로 설정
  return date.getTime();
};

export const operatingColors = (status: string | undefined) => {
  return status === 'ACTIVE'
    ? statusColors.ACTIVE
    : status === 'DISCONNECTED'
    ? statusColors.DISCONNECTED
    : status === 'INACTIVE'
    ? statusColors.INACTIVE
    : statusColors.DISCONNECTED;
};

export const equipmentName = ({
  dashboardId,
  data = [],
}: {
  dashboardId: number;
  data: domainT[];
}) => {
  return data.find((item: domainT) => item.dashboard?.id === dashboardId)
    ?.dashboard?.name;
};

export const sensorName = ({
  sensorId,
  data = [],
}: {
  sensorId: number;
  data: sensor[];
}) => {
  return data.find((item: sensor) => item.id === sensorId)?.name;
};

export const findSensorMaintenance = ({
  dashboardId,
  data,
}: {
  dashboardId: number;
  data: alarmManage[];
}) => {
  return (
    data.find((item) => item.dashboardId === dashboardId)?.sensorMaintenances ||
    []
  );
};

export const putAlarmManageData = (data: alarmManage[]) => {
  return data.map((item) => {
    return {
      dashboardId: item.dashboardId,
      accountId: item.dashboardManager.accountId,
      sensors: item.sensorMaintenances.map((sensor) => ({
        sensorId: sensor.sensorId,
        accountId: sensor.sensorManager.accountId,
      })),
    };
  });
};

export const findValue = (key: string, data: any) => {
  // 단일
  const keys = Object.keys(data);
  const hasDataKey = keys.includes(key);
  const keyData = data[key];
  if (hasDataKey) {
    return keyData;
  }
  // 다중 (.포함)
  let keyArr: any[] = [];
  let value: any = data;
  if (key.includes('.')) {
    keyArr = key.split('.');
  }
  keyArr.forEach((key) => {
    value = value[key];
  });
  return value;
};

export const hasDuplicates = (arr: number[]) => {
  const filteredArr = arr.filter((num) => num !== -1); // -1 제외
  const uniqueArr = [...new Set(filteredArr)]; // 중복 제거

  // 중복이 있으면 배열 길이가 다르므로 true 리턴
  return filteredArr.length !== uniqueArr.length;
};

export const makeRanges = (ranges: { lower: string; upper: string }[]) => {
  return Object.fromEntries(
    ranges.map((range: { lower: string; upper: string }, idx: number) => [
      idx,
      [range.lower, range.upper],
    ]),
  );
};
