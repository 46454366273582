import React from 'react';
import { useAppSelect } from 'store/configureStore.hooks';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { colors } from 'constants/colors';
interface IProps {
  children: React.ReactNode;
}
const MuiProvider: React.FC<IProps> = ({ children }) => {
  const themeInfo = useAppSelect((state) => state.theme);
  const selectedMode = themeInfo.data.mode === 'darkTheme' ? 'dark' : 'light';

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: selectedMode,
          primary: {
            main: colors.GREEN_BASE,
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                background:
                  'linear-gradient(0deg, #07d7a5 0%, #07d7a5 100%), #ec7263',
                color: '#2e363b',
                fontSize: '16px',
                borderRadius: '8px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                padding: '15px',
              },
              arrow: {
                color: '#07d7a5', // 화살표 색상
              },
            },
          },
        },
      })}
    >
      {children}
    </ThemeProvider>
  );
};

export default MuiProvider;
