export const MAIN_TABLE_PANEL = [
  {
    kr: '이름',
    en: 'domainName',
    width: '25%',
  },
  // {
  //   kr: '상태',
  //   en: 'operatingStatus.status',
  //   width: '15%',
  // },
  {
    kr: '알람',
    en: 'alarmCaseStatus',
    width: '30%',
  },
  {
    kr: '방지 / 배출 설비 가동 시간',
    en: 'operatingStatus.todayOperatingTime.seconds',
    width: '40%',
  },
  {
    kr: '',
    en: 'setting',
    width: '5%',
  },
];

export const SUB_TABLE_PANEL = [
  {
    kr: '',
    en: 'checkbox',
    width: '7.5%',
  },
  {
    kr: '이름',
    en: 'name',
    width: '38.5%',
  },
  {
    kr: '측정값',
    en: 'data.value',
    width: '30%',
  },
  {
    kr: '수신 시간',
    en: 'data.time',
    width: '15%',
  },
  {
    kr: '',
    en: 'setting',
    width: '10%',
  },
];

export const SUB_CONTROL_TABLE_PANEL = [
  {
    kr: '이름',
    en: 'name',
    width: '50%',
  },
  {
    kr: '마지막 제어 시간',
    en: 'createdAt',
    width: '25%',
  },
  {
    kr: '',
    en: 'setting',
    width: '25%',
  },
];

export const ACCOUNT_MANAGE = [
  {
    kr: 'No.',
    en: 'Index',
  },
  {
    kr: '이름',
    en: 'name',
  },
  {
    kr: '계정',
    en: 'loginId',
  },
  {
    kr: '전화번호',
    en: 'phone',
  },
  {
    kr: '이메일',
    en: 'email',
  },
  {
    kr: '계정 권한',
    en: 'role',
  },
  {
    kr: '마지막 로그인',
    en: 'lastLoginTime',
  },
  {
    kr: '옵션',
    en: 'option',
  },
];

export const SEARCH_ENTERPRISE = [
  {
    kr: 'No.',
    en: 'Index',
  },
  {
    kr: '기업명',
    en: 'name',
  },
  {
    kr: '코드',
    en: 'code',
  },
];

export const HIDE_SENSOR = [
  {
    kr: '체크박스',
    en: 'checkbox',
  },
  {
    kr: '센서명',
    en: 'name',
  },
  {
    kr: '등록 번호',
    en: 'id',
  },
];

export const CONTROLLER_LOG = [
  {
    kr: 'No.',
    en: 'Index',
  },
  {
    kr: '날짜',
    en: 'createdAt',
  },
  {
    kr: '제어 내용',
    en: 'log',
  },
  {
    kr: '제어자',
    en: 'username',
  },
];

export const ALARM_CASE_SET = [
  {
    kr: '우선 순위',
    en: 'priority',
  },
  {
    kr: '알람명',
    en: 'caseName',
    type: 'input',
  },
  {
    kr: '색상',
    en: 'color',
    type: 'color',
  },
  {
    kr: '알람 문자 발송',
    en: 'isPushSms',
    type: 'checkbox',
  },
  {
    kr: '알람 메일 발송',
    en: 'isPushMail',
    type: 'checkbox',
  },
  {
    kr: '알람 팝업 사용',
    en: 'isPushFloat',
    type: 'checkbox',
  },
  {
    kr: '',
    en: 'delete',
    type: 'delete',
  },
];

export const ALARM_STANDARD_SET = [
  {
    kr: '등록번호',
    en: 'id',
  },
  {
    kr: '센서명',
    en: 'name',
  },
];

export const ALARM_LIST = [
  {
    kr: 'No.',
    en: 'Index',
  },
  {
    kr: '알람 종류',
    en: 'type',
    type: 'tag',
  },
  {
    kr: '알람명',
    en: 'alarmCaseName',
    type: 'tag',
  },
  {
    kr: '설비명',
    en: 'dashboardName',
  },
  {
    kr: '발생 위치',
    en: 'sensor.name',
  },
  {
    kr: '내용',
    en: 'content',
  },
  {
    kr: '발생일',
    en: 'createdTime',
  },
  {
    kr: '접수자',
    en: 'receiveInfo.receiverName',
  },
  {
    kr: '상태',
    en: 'status',
    type: 'tag',
  },
  {
    kr: '',
    en: 'view',
    type: 'view',
  },
];

export const LIVE_ALARM_LIST = [
  {
    kr: '알람명',
    en: 'alarmCaseName',
    type: 'tag',
  },
  {
    kr: '설비명',
    en: 'dashboardName',
  },
  {
    kr: '발생 위치',
    en: 'sensor.name',
  },
  {
    kr: '내용',
    en: 'content',
  },
  {
    kr: '발생일',
    en: 'createdTime',
  },
  {
    kr: '',
    en: 'option',
  },
];

export const OPERATING_STATUS_SET = [
  {
    kr: '도메인명',
    en: 'dashboardName',
  },
  {
    kr: '가동 기준 센서',
    en: 'targetSensorId',
    type: 'select',
  },
  {
    kr: '가동 범위',
    en: 'range',
    type: 'inputPop',
  },
  {
    kr: '가동 색상',
    en: 'activeColor',
    type: 'color',
  },
  {
    kr: '비가동 색상',
    en: 'inactiveColor',
    type: 'color',
  },
];

export const NOTICE = [
  {
    kr: 'No.',
    en: 'ReverseIndex',
  },
  {
    kr: '제목',
    en: 'title',
  },
  {
    kr: '공지 대상',
    en: 'target',
  },
  {
    kr: '등록일',
    en: 'createdAt',
  },
];

const resize = -40;

const RIGHT_GAP_Y = 94.5;
const RIGHT_GAP_X = 108;
const LEFT_GAP_Y = 94.5;
const LEFT_GAP_X = 140;
const calculationLocation = ({ direction, x, y }) => {
  return direction === 'left'
    ? {
        animatedX: x,
        animatedY: y + resize,
        cardLeft: x - LEFT_GAP_X,
        cardTop: y - LEFT_GAP_Y + resize,
      }
    : {
        animatedX: x,
        animatedY: y + resize,
        cardLeft: x + RIGHT_GAP_X,
        cardTop: y - RIGHT_GAP_Y + resize,
      };
};

// part1 : 0,1,2,3 (inlet, outlet, 차압, ph)
// part2 : 3,4
// part3 : 5,6,7
// part4 : 8 ~

export const LOCATION_CARD = {
  0: calculationLocation({ direction: 'left', x: 180, y: 210 }),
  1: calculationLocation({ direction: 'right', x: 477, y: 263.5 }),
  2: calculationLocation({ direction: 'right', x: 445, y: 210.5 }),
  3: calculationLocation({ direction: 'left', x: 250, y: 182.5 }),

  4: calculationLocation({ direction: 'left', x: 321, y: 310 }),
  5: calculationLocation({ direction: 'right', x: 20, y: 160.5 }),
  // 6: calculationLocation({ direction: 'right', x: 442, y: 214.5 }),
  // 7: calculationLocation({ direction: 'right', x: 442, y: 214.5 }),
  // 8: calculationLocation({ direction: 'right', x: 442, y: 214.5 }),

  9: calculationLocation({ direction: 'right', x: 470, y: 200.5 }),
  10: calculationLocation({ direction: 'right', x: 295, y: 264.5 }),
  11: calculationLocation({ direction: 'left', x: 203, y: 160.5 }),
  12: calculationLocation({ direction: 'left', x: 203, y: 213.5 }),
};

export const VIBRATE_XYZ = 'vibrate_xyz';
export const AIR_HEADERS = 'air_header';

export const MULTI_NAMING = (key) => {
  switch (key) {
    case 6:
      return 'X';
    case 7:
      return 'Y';
    case 8:
      return 'Z';
    default:
      return key - 12;
  }
};

export const MULTI_LOCATION_CARD = (key) => {
  if (key === VIBRATE_XYZ) {
    return calculationLocation({ direction: 'right', x: 440, y: 358.5 });
  }

  if (key === AIR_HEADERS) {
    return calculationLocation({ direction: 'right', x: 446, y: 255.5 });
  }
};
