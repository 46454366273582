import { Alert, Snackbar } from '@mui/material';
import { colors } from 'constants/colors';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setOnSnackbar } from 'store/modules/pageInfo';
interface IProps {
  onSnackbar: boolean;
  content: string;
}

const MySnackbar: React.FC<IProps> = ({ onSnackbar, content }) => {
  const dispatch = useDispatch();
  return (
    <Snackbar
      open={onSnackbar}
      autoHideDuration={5000}
      onClose={() => dispatch(setOnSnackbar(false))}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        variant="filled"
        severity="success"
        onClose={() => dispatch(setOnSnackbar(false))}
        sx={{ color: colors.WHITE }}
      >
        {content}
      </Alert>
    </Snackbar>
  );
};

export default MySnackbar;
