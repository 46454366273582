import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

export type dustCollector = {
  data: {
    numberOfAirHeader: number;
    numOfBadge: number;
  };
};

const initialState = {
  data: {
    numOfAirHeader: 0,
    numOfBadge: 0,
  },
};

const dustCollectorSlice = createSlice({
  name: 'dustCollector',
  initialState,
  reducers: {
    setNumOfAirHeader: (state, action: PayloadAction<number>) => {
      state.data.numOfAirHeader = action.payload;
    },
    setNumOfBadge: (state, action: PayloadAction<number>) => {
      state.data.numOfBadge = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setNumOfAirHeader, setNumOfBadge } = dustCollectorSlice.actions;
export default dustCollectorSlice.reducer;
