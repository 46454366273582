/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from 'components/common/Modal';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { getDefaultWrap } from './common/modalCss';
import ModalTitle from './common/ModalTitle';
import AlarmBasicInfo from 'components/AlarmBasicInfo/AlarmBasicInfo';
import AlarmConfirmMessage from 'components/AlarmConfirmMessage/AlarmConfirmMessage';
import AlarmEnteredList from './AlarmEnteredList/AlarmEnteredList';
import { useAlarmDetail } from 'apiHooks/useAlarm';
import { INIT_ALARM_DETAIL } from 'services/init-data';
import AlarmManagerInfo from 'components/AlarmManagerInfo/AlarmManagerInfo';
import { useAppSelect } from 'store/configureStore.hooks';
import { colors } from 'constants/colors';
import ModalButton from './common/ModalButton';
import AlarmConfirmMessageModal from './AlarmConfirmMessageModal';

interface IProps {
  data: any;
  refetch?: () => void;
  onClose: () => void;
}

const AlarmDetailModal: React.FC<IProps> = ({ data, refetch, onClose }) => {
  const { data: detailData = INIT_ALARM_DETAIL, refetch: detailRefetch } =
    useAlarmDetail(data.id);
  const [onConfirmModal, setOnConfirmModal] = useState(false);

  const isModify = data.checks.length > 0;
  return (
    <Modal>
      <Wrap>
        <ModalTitle
          title="상세정보"
          onClose={onClose}
          textColor="#9a9a9a"
          fillColor="#9a9a9a"
        />
        <ContentWrap>
          <ListWrap>
            <h2>· 알람 정보</h2>
            <AlarmBasicInfo data={data} />
          </ListWrap>
          <ListWrap>
            <h2>· 접수자 정보</h2>
            <AlarmManagerInfo data={data.receiveInfo} />
          </ListWrap>
          <ListWrap>
            <h2>· 조치 내용</h2>
            <AlarmEnteredList data={detailData} />
          </ListWrap>
        </ContentWrap>
        <ModalButton
          onClose={onClose}
          btnText={`조치 내용 ${isModify ? '수정' : '등록'}`}
          onClick={() => setOnConfirmModal(true)}
        />
        {onConfirmModal && (
          <AlarmConfirmMessageModal
            data={data}
            detailData={detailData}
            isModify={isModify}
            refetch={refetch}
            detailRefetch={detailRefetch}
            onClose={() => setOnConfirmModal(false)}
          />
        )}
      </Wrap>
    </Modal>
  );
};

const Wrap = styled.div`
  ${getDefaultWrap};
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  width: 700px;
  height: 700px;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  color: ${({ theme }) => theme.TEXT_PRIMARY};

  h2 {
    font-size: 18px;
    font-weight: 600;
  }
`;

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const ModalBtn = styled.button`
  position: absolute;
  bottom: 20px;
  width: 120px;
  padding: 8px 24px;
  align-self: center;
  color: ${colors.GREEN_BASE};
  background: ${colors.BLACK};
  border-radius: 6px;
  border: 0;
  outline: 0;
  font-weight: 600;
`;

export default AlarmDetailModal;
