/* eslint-disable @typescript-eslint/no-explicit-any */
import store from 'store/configureStore';
import { api, apiError } from './common';

export const postEquipmentImg = async (prms: FormData) => {
  const dashboardId = store.getState().pageInfo.dashboardId;
  try {
    const { data } = await api.patch(`/dashboards/${dashboardId}`, prms, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const deleteEquipmentImg = async (prms: FormData) => {
  const dashboardId = store.getState().pageInfo.dashboardId;
  try {
    const { data } = await api.patch(`/dashboards/${dashboardId}`, prms, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const patchDisplayOrders = async (orderList: string) => {
  const dashboardId = store.getState().pageInfo.dashboardId;
  try {
    const { data } = await api.patch(
      `/dashboards/${dashboardId}/sensors/display-orders?ids=${orderList}`,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};
