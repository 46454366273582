import React, { ChangeEvent, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.module.css';
import { styled } from 'styled-components';
import { ReactComponent as DashSVG } from 'assets/img/dash.svg';
import { ReactComponent as CalendarSVG } from 'assets/img/calendar.svg';
import { calPressBtn, convertDate } from 'services/miscFunc';
import { colors } from 'constants/colors';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Tooltip } from '@mui/material';

interface IProps {
  startDate: number;
  endDate: number;
  children?: React.ReactNode;
  onChangeStartDate: (date: ChangeEvent<HTMLInputElement>) => void;
  onChangeEndDate: (date: ChangeEvent<HTMLInputElement>) => void;
  onClickDateBtn: (beforeNum: number) => void;
  isDateNumHide?: boolean;
}

export const buttonData = [1, 7, 30, 90];

const MyDatePicker: React.FC<IProps> = ({
  startDate,
  endDate,
  children,
  isDateNumHide,
  onChangeStartDate,
  onChangeEndDate,
  onClickDateBtn,
}) => {
  const [btnRangeNum, setBtnRangeNum] = useState(-1);
  const dateListbtn = () => {
    return (
      <ButtonWrap>
        {buttonData.map((num: number) => (
          <DateBtn
            $isPress={calPressBtn(startDate, endDate) === num}
            key={num}
            onClick={() => {
              onClickDateBtn(num);
              setBtnRangeNum(num);
            }}
          >
            {`${num}일 전`}
          </DateBtn>
        ))}
      </ButtonWrap>
    );
  };

  return (
    <Wrap>
      <input
        type="datetime-local"
        value={convertDate(startDate)}
        max={endDate}
        onChange={(e) => {
          onChangeStartDate(e);
          setBtnRangeNum(-1);
        }}
      />
      <DashIcon />
      <input
        type="datetime-local"
        value={convertDate(endDate)}
        onChange={(e) => {
          onChangeEndDate(e);
          setBtnRangeNum(-1);
        }}
      />
      {isDateNumHide && (
        <Tooltip
          title={dateListbtn()}
          placement="bottom"
          componentsProps={tooltipSx}
        >
          <TooltipWrap>
            <span>
              {btnRangeNum !== -1 ? `${btnRangeNum}일 전` : '범위 선택'}
            </span>
            <DateRangeIcon
              sx={{
                width: '15px',
                height: '15px',
                color: 'black',
                marginBottom: '1px',
              }}
            />
          </TooltipWrap>
        </Tooltip>
      )}
      {!isDateNumHide && dateListbtn()}
      {children}
    </Wrap>
  );
};
// 1. 눌렀을 때 눌린 색상
// 2. date를 클릭하고 start,end 중 값이 변경되었을 때 눌린거 해제

//TODO: 버튼정리 및 아이콘 회색

const Wrap = styled.div`
  display: flex;
  align-items: center;
  > input {
    width: 250px;
    height: 30px;
    font-size: 14px;
    text-align: center;
    background: linear-gradient(0deg, #7e899e 0%, #7e899e 100%), #6c778d;
    color: ${({ theme }) => theme.BUTTON_SECONDARY_COLOR};
    border: none;
    font-weight: 600;
    border-radius: 4px;
    padding: 2px 8px;
  }

  > span:nth-child(1) {
    font-size: 15px;
    font-weight: 600;
  }
`;

const DashIcon = styled(DashSVG)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  margin: 0 8px;
`;

const CalendarIcon = styled(CalendarSVG)`
  width: 20px;
  height: 20px;
  margin-right: 16px;
  fill: ${({ theme }) => theme.TEXT_PRIMARY};
`;

const ButtonWrap = styled.div`
  padding-left: 16px;
  display: flex;
  gap: 8px;
`;

const DateBtn = styled.div<{ $isPress: boolean }>`
  border: none;
  border: 1px solid ${({ theme }) => theme.TEXT_PRIMARY}22;
  background: ${({ $isPress }) =>
    $isPress
      ? 'linear-gradient(0deg, #C7D3EB 0%, #C7D3EB 100%), linear-gradient(0deg, #303848 0%, #303848 100%), #6C778D'
      : '#6C778D'};
  color: ${colors.NEUTRAl_400};
  padding: 6px 8px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;

  transition: background-color 0.3s;

  &:active {
    background: none;
    background-color: ${colors.BLUE_LIGHT};
  }
`;

const tooltipSx = {
  tooltip: {
    sx: {
      maxWidth: 'none',
      padding: 0,
      paddingRight: '16px',
      paddingTop: '16px',
      paddingBottom: '16px',
      background: colors.NEUTRAl_600,
    },
  },
};

const TooltipWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 16px;
  padding: 7.4px 18px;
  border-radius: 4px;
  background: linear-gradient(0deg, #7e899e 0%, #7e899e 100%), #6c778d;
  cursor: pointer;

  > span {
    width: 60px;
    font-size: 14px;
    font-weight: 600;
  }
`;

export default MyDatePicker;
