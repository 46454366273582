/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventSourcePolyfill } from 'event-source-polyfill';
import { v2 } from 'apis/common';
import React, { useEffect, useState } from 'react';
import { useAppSelect } from 'store/configureStore.hooks';
import { notification } from 'antd';

const init = {
  type: '',
  status: '',
  title: '',
  content: '',
  statusColor: '',
  dashboardName: '',
  domainName: '',
  id: 0,
  createdTime: '',
  receiverName: '',
  receiverLoginId: '',
  sensorId: -1,
};

const exportInit = {
  state: '',
  description: '',
  url: '',
};

const useSSE = () => {
  const [floatAlarmData, setFloatAlarmData] = useState(init);
  const [sensorExportData, setSensorExportData] = useState(exportInit);
  const { accessToken } = useAppSelect((state) => state.accountInfo.token);
  const [noticeApi, contextHolder] = notification.useNotification({
    stack: { threshold: 1 },
  });

  const EventSource = EventSourcePolyfill;

  useEffect(() => {
    if (!accessToken) {
      return;
    }
    const eventSource = new EventSource(
      `${process.env.REACT_APP_BE_URL}${v2}/events/connect`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        heartbeatTimeout: Number.MAX_SAFE_INTEGER,
        withCredentials: true,
      },
    );

    if (accessToken) {
      eventSource.addEventListener('connect', (e) => {
        console.log(e);
      });

      eventSource.addEventListener('sensorDataExport', (e: any) => {
        const data = JSON.parse(e.data);
        setSensorExportData(data);
      });

      eventSource.addEventListener('event', (e: any) => {
        console.log(e);
        const data = JSON.parse(e.data);
        setFloatAlarmData(data);
      });

      eventSource.onmessage = async (e: any) => {
        console.log(e);
      };

      eventSource.onerror = (e: any) => {
        console.log(e);
      };
    }

    return () => {
      eventSource.close();
    };
  }, [accessToken]);
  return { floatAlarmData, noticeApi, contextHolder, sensorExportData };
};

export default useSSE;
