import { useAlarmDetail } from 'apiHooks/useAlarm';
import AlarmBasicInfo from 'components/AlarmBasicInfo/AlarmBasicInfo';
import AlarmManagerInfo from 'components/AlarmManagerInfo/AlarmManagerInfo';
import M_alarmBasicInfo from 'components/m/M_AlarmBasicInfo';
import M_AlarmEnteredList from 'components/m/M_AlarmEnteredList';
import M_AlarmManagerInfo from 'components/m/M_AlarmManagerInfo';
import AlarmEnteredList from 'components/ModalContents/AlarmEnteredList/AlarmEnteredList';
import M_AlarmConfirmMessageModal from 'components/ModalContents/m/M_AlarmConfirmMessageModal';
import { colors } from 'constants/colors';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { INIT_ALARM_DETAIL } from 'services/init-data';
import styled from 'styled-components';

const AlarmReceive = () => {
  const [onModal, setOnModal] = useState(false);
  const location = useLocation();
  const alarmId = location.state?.alarmId || sessionStorage.getItem('alarmId');
  const { data: detailData = INIT_ALARM_DETAIL, refetch: detailRefetch } =
    useAlarmDetail(alarmId);

  console.log(alarmId, detailData);
  const isModify = detailData.checks.length > 0;

  useEffect(() => {
    if (location.state?.alarmId) {
      sessionStorage.setItem('alarmId', location.state.alarmId);
    }
  }, [location.state?.alarmId]);

  return (
    <Wrap>
      <Title>알람 상세 정보</Title>
      <ContentWrap>
        <ListWrap>
          <h2>· 알람 정보</h2>
          <M_alarmBasicInfo data={detailData} />
        </ListWrap>
        <ListWrap>
          <h2>· 접수자 정보</h2>
          <M_AlarmManagerInfo data={detailData.receiveInfo} />
        </ListWrap>
        <ListWrap>
          <h2>· 조치 내용</h2>
          <M_AlarmEnteredList data={detailData} />
        </ListWrap>
      </ContentWrap>
      <MyButton onClick={() => setOnModal(true)}>
        {`조치 내용 ${isModify ? '수정' : '등록'}`}
      </MyButton>
      {onModal && (
        <M_AlarmConfirmMessageModal
          onClose={() => setOnModal(false)}
          detailData={detailData}
          detailRefetch={detailRefetch}
          isModify={isModify}
        />
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  min-width: 320px;
  max-width: 600px;
  width: 100%;
  background-color: #596379;
  min-height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  padding-bottom: 60px;
`;

const Title = styled.h3`
  font-size: 18px;
  color: ${colors.NEUTRAl_300};
  font-weight: 600;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: ${({ theme }) => theme.TEXT_PRIMARY};

  h2 {
    font-size: 18px;
    font-weight: 600;
  }
`;

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const MyButton = styled.button`
  width: 80%;
  background-color: ${colors.GREEN_BASE};
  color: ${colors.BLACK};
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  outline: 0;
  padding: 8px 46px;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
`;

export default AlarmReceive;
