/* eslint-disable @typescript-eslint/no-explicit-any */
import store from 'store/configureStore';
import { api, apiError, v2 } from './common';
import { alarmCaseType, putAlarmManageT } from 'types/alarm-T';

export const getAlarmCaseList = async () => {
  const companyId = store.getState().accountInfo.info.companyId;
  try {
    const { data } = await api.get(`${v2}/companies/${companyId}/alarm-cases`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const postAlarmCases = async (prms: alarmCaseType[]) => {
  const companyId = store.getState().accountInfo.info.companyId;
  try {
    const { data } = await api.post(
      `${v2}/companies/${companyId}/alarm-cases`,
      prms,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const putAlarmCases = async (prms: alarmCaseType[]) => {
  const companyId = store.getState().accountInfo.info.companyId;
  try {
    const { data } = await api.put(
      `${v2}/companies/${companyId}/alarm-cases`,
      prms,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const deleteAlarmCases = async (caseId: number) => {
  const companyId = store.getState().accountInfo.info.companyId;
  try {
    const { data } = await api.delete(
      `${v2}/companies/${companyId}/alarm-cases?ids=${caseId}`,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getAlarmRules = async (dashboardId: number) => {
  try {
    const { data } = await api.get(
      `${v2}/dashboards/${dashboardId}/alarm-rules`,
    );
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const putAlarmRules = async (prms: any) => {
  const dashboardId = prms.dashboardId;
  delete prms.dashboardId;

  try {
    const { data } = await api.put(
      `${v2}/dashboards/${dashboardId}/alarm-rules`,
      prms.prms,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const deleteAlarmRules = async (prms: any) => {
  const dashboardId = prms.dashboardId;
  delete prms.dashboardId;

  try {
    const { data } = await api.delete(
      `${v2}/dashboards/${dashboardId}/alarm-rules?ids=${prms.prms.join()}`,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getAlarmList = async (prms?: any) => {
  const companyId = store.getState().accountInfo.info.companyId;
  try {
    let cvtPrms = '';
    const keys = Object.keys(prms || {});
    keys.forEach((key) => {
      if (prms[key] !== undefined) {
        cvtPrms +=
          cvtPrms.length === 0
            ? `?${key}=${prms[key]}`
            : `&${key}=${prms[key]}`;
      }
    });
    const { data } = await api.get(
      `${v2}/companies/${companyId}/alarms${cvtPrms}`,
    );
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getAlarmListType = async () => {
  try {
    const { data } = await api.get(`${v2}/alarms/types`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getAlarmListStatus = async () => {
  try {
    const { data } = await api.get(`${v2}/alarms/status`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getAlarmDetail = async (alarmId: number) => {
  try {
    const { data } = await api.get(`${v2}/alarms/${alarmId}`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const patchEnteredContentAlarm = async (
  prms: FormData,
  alarmId: number,
) => {
  // const alarmId = prms.id;
  // delete prms.id;
  try {
    const { data } = await api.patch(`${v2}/alarms/${alarmId}/checks`, prms, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const patchAllAlarmChecked = async (sensorId?: number) => {
  const companyId = store.getState().accountInfo.info.companyId;
  let url = `${v2}/alarms/checks?companyId=${companyId}`;
  sensorId ? (url += `&sensorId=${sensorId}`) : '';

  try {
    const { data } = await api.patch(url, {});
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getEquipmentAlarmManage = async () => {
  const companyId = store.getState().accountInfo.info.companyId;
  try {
    const { data } = await api.get(
      `${v2}/companies/${companyId}/dashboards/managers`,
    );
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const putAlarmManage = async (prms: putAlarmManageT[]) => {
  const companyId = store.getState().accountInfo.info.companyId;
  try {
    const { data } = await api.put(
      `${v2}/companies/${companyId}/dashboards/managers`,
      prms,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getFile = async (filepath: string) => {
  try {
    const { data, headers } = await api.get(`/download?filepath=${filepath}`, {
      responseType: 'blob',
    });
    // 파일의 MIME 타입을 서버 응답 헤더에서 가져옵니다.
    const contentType = headers['content-type'];
    // Blob 객체 생성
    const blob = new Blob([data], { type: contentType });
    const url = window.URL.createObjectURL(blob);

    // 다운로드 링크 생성
    const a = document.createElement('a');
    a.href = url;
    a.download = filepath.split('/').pop() || 'download'; // 파일 이름 설정
    document.body.appendChild(a);
    a.click();

    // 링크 제거
    a.remove();
    window.URL.revokeObjectURL(url);

    return true;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};
