import Modal from 'components/common/Modal';
import { colors } from 'constants/colors';
import React, { useState, ChangeEvent, KeyboardEvent } from 'react';
import { SEARCH_ENTERPRISE } from 'services/table-text';
import { styled } from 'styled-components';
import { companyInfo, companyList } from 'types/account-T';
import { tableHeaderType } from 'types/table-T';
import { ReactComponent as SearchSVG } from 'assets/img/search.svg';
import ModalTitle from './common/ModalTitle';
import Pagination from 'components/common/Pagination';
import { useEnterprise } from 'apiHooks/useEnterprise';
interface IProps {
  onChangeCompanyInfo: (data: companyInfo) => void;
  onClose: () => void;
}

const SearchEnterprise: React.FC<IProps> = ({
  onChangeCompanyInfo,
  onClose,
}) => {
  const [companyList, setCompanyList] = useState<companyInfo[]>([]);
  const [searchText, setSearchText] = useState('');
  const [curPage, setCurPage] = useState(1);
  const articlePerPage = 10;

  const { data = [], isLoading, refetch } = useEnterprise(setCompanyList);

  const handleClickSearch = () => {
    if (searchText.length === 0) {
      refetch();
    }

    setCurPage(1);
    setCompanyList(
      data.filter((item: companyInfo) => item.name.includes(searchText)),
    );
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      handleClickSearch();
    }
  };

  const filteredCompanyList = companyList.slice(
    (curPage - 1) * articlePerPage,
    articlePerPage * curPage,
  );

  return (
    <Modal>
      <Wrap>
        <ModalTitle title="기업정보 조회" onClose={onClose} />
        <SearchWrap>
          <input
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchText(e.target.value)
            }
            placeholder="기업명"
            onKeyDown={handleKeyDown}
          />
          <SearchIcon onClick={handleClickSearch} />
        </SearchWrap>
        <TableBgWrap>
          <Table>
            <HeaderWrap>
              <tr>
                {SEARCH_ENTERPRISE.map((data: tableHeaderType) => (
                  <Th key={data.en}>{data.kr}</Th>
                ))}
              </tr>
            </HeaderWrap>
            <BodyWrap>
              {filteredCompanyList.map((data: companyInfo, idx: number) => (
                <tr key={data.id} onClick={() => onChangeCompanyInfo(data)}>
                  {SEARCH_ENTERPRISE.map((hData: tableHeaderType) => {
                    const keys = Object.keys(data);
                    const hasDataKey = keys.includes(hData.en);
                    const keyData = data[hData.en as keyof companyInfo];

                    if (hData.en === 'Index') {
                      const rowIdx = (curPage - 1) * 10 + (idx + 1);
                      return <Td key={rowIdx}>{rowIdx}</Td>;
                    }

                    if (hasDataKey) {
                      return <Td key={hData.kr}>{keyData}</Td>;
                    }
                  })}
                </tr>
              ))}
            </BodyWrap>
          </Table>
        </TableBgWrap>
        <Pagination
          curPage={curPage}
          data={companyList}
          articlePerPage={articlePerPage}
          setCurPage={setCurPage}
        />
        <ModalBtn onClick={onClose}>취소</ModalBtn>
      </Wrap>
    </Modal>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.CARD_BG_PRIMARY};
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  padding: 24px 48px;
  margin-top: 36px;
  height: 90%;
  width: 650px;
  height: 600px;
  gap: 20px;
  border-radius: 16px;
`;

const SearchWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 16px;
  width: 360px;

  > input {
    color: ${colors.WHITE};
    width: 100%;
    padding: 12px;
    padding-right: 34px;
    font-size: 15px;
    border-radius: 4px;
    border: none;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.2) 100%
      ),
      #7e899e;
    &::placeholder {
      color: ${colors.WHITE};
      opacity: 0.4;
    }
  }
`;

const SearchIcon = styled(SearchSVG)`
  position: absolute;
  width: 22px;
  height: 22px;
  cursor: pointer;
  top: 10px;
  right: 12px;
  z-index: 100;
`;

const TableBgWrap = styled.div`
  height: 85%;
  background-color: #647189;
`;

const Table = styled.table`
  width: 100%;
  max-height: 100%;
  /* height: 100%; */
`;

const HeaderWrap = styled.thead`
  background-color: #7e899e;
  color: ${({ theme }) => theme.WHITE};
`;

const Th = styled.th`
  padding: 8px 4px;
  font-size: 15px;
`;

const Td = styled.td`
  text-align: center;
  padding: 8px 12px;
  font-size: 15px;
  color: ${colors.WHITE};

  &:nth-child(1) {
    opacity: 0.5;
  }
`;

const BodyWrap = styled.tbody`
  width: 100%;
  background-color: #647189;

  > tr {
    width: 100%;
    padding: 8px 12px;
    cursor: pointer;
    &:hover {
      background-color: #7e899e;
    }

    > td:nth-child(1) {
      width: 15%;
      text-align: center;
    }

    > td:nth-child(2) {
      width: 65%;
      /* white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; */
    }

    > td:nth-child(3) {
      width: 20%;
    }
  }
`;

const ModalBtn = styled.button`
  padding: 8px 46px;
  align-self: center;
  color: ${colors.GREEN_BASE};
  background: ${colors.BLACK};
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  border: 0;
  outline: 0;
  font-weight: 600;
  font-size: 16px;
`;

export default SearchEnterprise;
