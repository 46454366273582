import { useMutation, useQuery } from '@tanstack/react-query';
import { postLogin } from 'apis/account-rest';
import { decodeToken } from 'apis/auth-rest';
import { queryKeys } from 'services/queryKeys';
import { login } from 'types/account-T';

export const useLogin = () => {
  return useMutation([queryKeys.LOGIN], (prms: login) => postLogin(prms));
};

export const useDecodeToken = (token: string) => {
  return useQuery([queryKeys.LOGIN, 'token-mobile'], () => decodeToken(token));
};
