/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styled from 'styled-components';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tag from 'components/Tag/Tag';
import { getFile } from 'apis/alarm-rest';
import { Tooltip, Typography } from '@mui/material';
interface IProps {
  data: any;
}
const M_AlarmEnteredList: React.FC<IProps> = ({ data }) => {
  const content = data.checks[0];
  const handleClickFileDownload = async (filePath: string) => {
    const res = await getFile(filePath);
  };
  const isAttachmentFile = content?.attachmentName && content?.attachmentPath;

  if (data.checks.length === 0)
    return (
      <EmptyWrap>
        <span>조치 내용을 등록해주세요.</span>
      </EmptyWrap>
    );

  return (
    <Table>
      <tbody>
        <tr>
          <th>상태</th>
          <td>{data.checks.length > 0 && <Tag text={content?.status} />}</td>
        </tr>
        <tr>
          <th>조치일</th>
          <td>{data.checks.length > 0 && content.createTime}</td>
        </tr>
        <tr>
          <th>등록자</th>
          <td>
            {data.checks.length > 0 &&
              `${content.accountName}(${content.accountLoginId})`}
          </td>
        </tr>
        <tr>
          <th>첨부 파일</th>
          <AttachTd>
            {data.checks.length > 0 && content.attachmentName}
            {isAttachmentFile && (
              <Tooltip
                placement="bottom"
                arrow
                title={
                  <Typography fontSize={14} fontWeight={600}>
                    {content.attachmentName}
                  </Typography>
                }
              >
                <FileDownloadBtn
                  onClick={() =>
                    handleClickFileDownload(content.attachmentPath)
                  }
                />
              </Tooltip>
            )}
          </AttachTd>
        </tr>
        <tr>
          <th>조치 내용</th>
          <td>{data.checks.length > 0 && content.message}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  position: relative;
  width: 100%;
  th {
    background: #6c6c6c;
    color: ${({ theme }) => theme.WHITE};
    width: 80px;
    height: 43px;
    font-size: 14px;
    font-weight: 500;
    vertical-align: middle;
  }
  tr {
    border-top: 1px solid #9a9a9a;
    border-bottom: 1px solid #9a9a9a;
  }
  td {
    vertical-align: middle;
    padding-left: 20px;
    font-size: 14px;
  }
`;

const AttachTd = styled.td`
  padding-right: 16px;
  position: relative;
`;

const FileDownloadBtn = styled(FileDownloadIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -6px;
  cursor: pointer;
`;

const EmptyWrap = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #9a9a9a;
  border-bottom: 1px solid #9a9a9a;
`;

export default M_AlarmEnteredList;
