import { useDecodeToken, useLogin } from 'apiHooks/useLogin';
import { colors } from 'constants/colors';
import routePath from 'constants/route';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  accountInfo,
  setAccessToken,
  setAccountInfo,
  setRefreshToken,
} from 'store/modules/account';
import styled, { css } from 'styled-components';
import { validationPassword } from 'validation/account';
import jwt_decode from 'jwt-decode';
const AlarmCheckLogin = () => {
  const dispatch = useDispatch();
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordFocus, setIsPasswordFocus] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const { data = { loginId: '', alarmId: -1 } } = useDecodeToken(token || '');
  console.log(data);
  const { mutateAsync } = useLogin();

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    setAccount(data.loginId);
  }, [data]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const prms = {
      loginId: account,
      password,
    };

    const res = await mutateAsync(prms);
    if (res?.success) {
      const { accessToken: loginAccessToken, refreshToken } = res.data;
      const decodeBody: accountInfo = jwt_decode(loginAccessToken);
      dispatch(setAccessToken(loginAccessToken));
      dispatch(setRefreshToken(refreshToken));
      dispatch(setAccountInfo(decodeBody));

      navigate(routePath.ALARM_RECEIVE, {
        state: { alarmId: data.alarmId },
      });
    }
  };

  return (
    <Wrap onSubmit={onSubmit}>
      <HeaderWrap>
        <span>IEMS LOGIN</span>
        <span>Please enter PW</span>
      </HeaderWrap>
      <ContentWrap>
        <EnterpriseWrap>
          <div>
            <span>ID</span>
            <MyInput value={account} placeholder="ID" disabled />
          </div>
        </EnterpriseWrap>
        <EnterpriseWrap>
          <div>
            <span>PW</span>
            <MyInput
              type="password"
              value={password}
              placeholder="PW"
              onChange={handleChangePassword}
              required
              onFocus={() => {
                if (!validationPassword(password).status)
                  setIsPasswordFocus(true);
              }}
              onBlur={() => setIsPasswordFocus(false)}
            />
          </div>
          {isPasswordFocus && (
            <PwNotice>
              특수문자, 영문, 숫자를 포함하여 8글자 이상 16글자 이하를
              입력해주세요.
            </PwNotice>
          )}
        </EnterpriseWrap>
      </ContentWrap>
      <LoginBtn type="submit">Login</LoginBtn>
    </Wrap>
  );
};

//TODO: 알람ID가 없다면 알람 체크 로그인으로 다시 돌아와야 함

const Wrap = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  min-width: 320px;
  max-width: 600px;
  height: 400px;
  width: 100%;
  color: ${colors.WHITE};
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px;
  border-radius: 8px;
`;

const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span:nth-child(1) {
    font-size: 24px;
    font-family: 'GmarketSans_B';
    font-weight: 700;
  }

  > span:nth-child(2) {
    font-size: 16px;
    padding-top: 3px;
    font-family: 'Pretendard_L';
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const EnterpriseWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  > span {
    min-width: 50px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;

    > span {
      font-size: 15px;
      font-weight: 500;
      min-width: 30px;
      text-align: left;
    }
  }
`;

const defaultInputCss = css`
  height: 42px;
  border-radius: 8px;
  background: transparent;
  padding: 4px 24px;
`;

const MyInput = styled.input`
  ${defaultInputCss};
  width: 90%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #7e899e;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  font-family: 'Pretendard_R';
  font-size: 16px;
  &::placeholder {
    color: ${colors.WHITE};
    opacity: 0.4;
    font-family: 'Pretendard_R';
  }
`;

const LoginBtn = styled.button`
  color: ${colors.BLACK};
  padding: 8px 40px;
  background: linear-gradient(0deg, #07d7a5 0%, #07d7a5 100%), #22232b;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 17px;
  font-weight: 600;
  margin-top: 16px;
`;

const PwNotice = styled.p`
  padding-bottom: 16px;
  color: ${colors.GREEN_BASE};
  font-size: 12px;
  text-align: center;
`;

export default AlarmCheckLogin;
