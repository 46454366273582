/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from 'components/common/Modal';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DeleteSVG } from 'assets/img/delete.svg';
import { colors } from 'constants/colors';
import { getDefaultWrap } from '../common/modalCss';
import M_AlarmConfirmMessage from 'components/m/M_AlarmConfirmMessage';
// import M_AlarmConfirmMessage from 'components/m/M_AlarmConfirmMessage';
interface IProps {
  detailData: any;
  isModify: boolean;
  detailRefetch: () => void;
  onClose: () => void;
}
const M_AlarmConfirmMessageModal: React.FC<IProps> = ({
  detailData,
  isModify,
  detailRefetch,
  onClose,
}) => {
  const [curWidth, setCurWidth] = useState(window.innerWidth);

  useEffect(() => {
    setCurWidth(window.innerWidth);
  }, [window.innerWidth]);
  return (
    <Modal>
      <Wrap $width={curWidth}>
        <HeaderWrap>
          <span>{`조치 내용 ${isModify ? '수정' : '등록'}`}</span>
          <DeleteIcon onClick={onClose} />
        </HeaderWrap>
        <M_AlarmConfirmMessage
          isModify={isModify}
          detailData={detailData}
          detailRefetch={detailRefetch}
          onClose={onClose}
        />
      </Wrap>
    </Modal>
  );
};

const Wrap = styled.div<{ $width: number }>`
  ${getDefaultWrap};
  width: ${({ $width }) => $width}px;
  max-width: 600px;
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'GmarketSans_R';
  font-size: 17px;
  align-items: center;
  color: ${colors.WHITE};
`;

const DeleteIcon = styled(DeleteSVG)<{ $fillColor?: string }>`
  width: 18px;
  height: 18px;
  cursor: pointer;
  fill: ${colors.WHITE};
`;

export default M_AlarmConfirmMessageModal;
