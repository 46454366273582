import { colors } from 'constants/colors';
import routePath from 'constants/route';
import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  accountInfo,
  initialState,
  setAccessToken,
  setAccountInfo,
  setAccountInfoReset,
  setCompanyId,
  setRefreshToken,
} from 'store/modules/account';
import { setDashboardId, setPageInfoReset } from 'store/modules/pageInfo';
import { css, styled } from 'styled-components';
import jwt_decode from 'jwt-decode';
import { ReactComponent as SearchSVG } from 'assets/img/search.svg';
import SearchEnterprise from 'components/ModalContents/SearchEnterprise';
import { companyInfo } from 'types/account-T';
import { validationPassword } from 'validation/account';
import ChangeFirstPw from 'components/ModalContents/ChangeFirstPw';
import { useLogin } from 'apiHooks/useLogin';
import { setResetError } from 'store/modules/error';
import { useEnterprise } from 'apiHooks/useEnterprise';
import { useAppSelect } from 'store/configureStore.hooks';

const Login = () => {
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const { info: accountInfo } = useAppSelect((state) => state.accountInfo);
  const [onSearchModal, setOnSearchModal] = useState(false);
  const [isAccountFocus, setIsAccountFocus] = useState(false);
  const [isPasswordFocus, setIsPasswordFocus] = useState(false);
  const [isAllEnteredData, setIsAllEnteredData] = useState(true);
  const [isFirstPw, setIsFirstPw] = useState(false);
  const [decodeTokenData, setDecodeTokenData] = useState<accountInfo>(
    initialState.info,
  );
  // const {
  //   data: onpremiseCompanyInfo = [],
  //   isLoading,
  //   refetch,
  // } = useEnterprise();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutateAsync } = useLogin();

  const isOnpremise = process.env.REACT_APP_ONPREMISE === 'true';
  useEffect(() => {
    dispatch(setPageInfoReset());
    dispatch(setAccountInfoReset());
  }, []);

  // useEffect(() => {
  //   // onpremise or aws
  //   if (isOnpremise && onpremiseCompanyInfo.length > 0) {
  //     const data = onpremiseCompanyInfo[0];
  //     setCompanyInfo((prev) => ({ ...prev, id: data.id, name: data.name }));
  //   }
  // }, [onpremiseCompanyInfo.length]);

  useEffect(() => {
    const validationPw = validationPassword(password);

    if (account.length > 3) {
      setIsAccountFocus(false);
    }
    if (validationPw.status) {
      setIsPasswordFocus(false);
    }
  }, [account, password]);

  const handleChangeAccount = (e: ChangeEvent<HTMLInputElement>) => {
    setAccount(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleChangeCompanyInfo = (data: companyInfo) => {
    dispatch(setAccountInfo({ ...accountInfo, companyId: data.id }));
    setOnSearchModal(false);
    navigate(routePath.MAIN);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const prms = {
      loginId: account,
      password,
    };

    const isEmpty = [account, password].includes('');

    if (isEmpty) {
      setIsAllEnteredData(false);
      //TODO: validation 처리
      return;
    }
    const res = await mutateAsync(prms);

    if (res?.success) {
      //TODO: accessToken 처리할 때, 여기서 ChangeFirstPw 처리하기

      // 토큰을 받아와서 처음인지 아닌지 여부 따지기
      // 처음이 아니면 그냥 통과
      // 처음이면 새로운 비밀번호 변경 -> 변경이 되었으면 다음 페이지로 이동(변경되었다는 알람)
      const { accessToken: loginAccessToken, refreshToken } = res.data;
      dispatch(setDashboardId(-1));
      const decodeBody: accountInfo = jwt_decode(loginAccessToken);
      dispatch(setAccessToken(loginAccessToken));
      dispatch(setRefreshToken(refreshToken));
      const adminAuth = decodeBody.companyId === 1;
      dispatch(setAccountInfo(decodeBody));
      if (adminAuth) {
        setOnSearchModal(true);
        return;
      }

      if (!decodeBody.lastLoginTime) {
        setIsFirstPw(true);
        setDecodeTokenData(decodeBody);
        return;
      }

      if (!adminAuth) {
        navigate(routePath.MAIN);
      }
    }
  };

  // const hasCompanyId = companyInfo.id !== -1;

  return (
    <Wrap onSubmit={handleSubmit}>
      <ContentWrap>
        <HeaderWrap>
          <span>IEMS SERVICE LOGIN</span>
          <span>Please enter ID and PW</span>
        </HeaderWrap>
        <InputWrap>
          {/* <EnterpriseWrap>
            <span>기업명</span>
            <EnterpriseInfoWrap
              $hasCompanyId={hasCompanyId}
              onClick={() => setOnSearchModal(true)}
            >
              <span>
                {hasCompanyId ? companyInfo.name : '기업을 선택해주세요.'}
              </span>
              {!isOnpremise && (
                <SearchIcon onClick={() => setOnSearchModal(true)} />
              )}
            </EnterpriseInfoWrap>
          </EnterpriseWrap> */}
          <EnterpriseWrap>
            <div>
              <span>ID</span>
              <MyInput
                value={account}
                onChange={handleChangeAccount}
                placeholder="ID"
                onFocus={() => {
                  setIsAllEnteredData(true);
                  if (account.length < 4) setIsAccountFocus(true);
                }}
                onBlur={() => setIsAccountFocus(false)}
              />
            </div>
          </EnterpriseWrap>
          <EnterpriseWrap>
            <div>
              <span>PW</span>
              <MyInput
                type="password"
                value={password}
                placeholder="PW"
                onChange={handleChangePassword}
                onFocus={() => {
                  setIsAllEnteredData(true);
                  if (!validationPassword(password).status)
                    setIsPasswordFocus(true);
                }}
                onBlur={() => setIsPasswordFocus(false)}
              />
            </div>
          </EnterpriseWrap>
        </InputWrap>
        {isAccountFocus && <p>ID를 4글자 이상 입력해주세요.</p>}
        {isPasswordFocus && (
          <p>
            특수문자, 영문, 숫자를 포함하여 8글자 이상 16글자 이하를
            입력해주세요.
          </p>
        )}
        {!isAllEnteredData && <p>로그인 정보를 모두 입력해주세요.</p>}
        <LoginBtn type="submit">Login</LoginBtn>
      </ContentWrap>
      {onSearchModal && (
        <SearchEnterprise
          onChangeCompanyInfo={handleChangeCompanyInfo}
          onClose={() => setOnSearchModal(false)}
        />
      )}
      {isFirstPw && (
        <ChangeFirstPw
          accountInfo={decodeTokenData}
          onClose={() => {
            setIsFirstPw(false);
          }}
        />
      )}
    </Wrap>
  );
};

//TODO: 최초 로그인 시, 비밀번호 변경창

const defaultInputCss = css`
  width: 400px;
  height: 42px;
  border-radius: 8px;
  background: transparent;
  padding: 4px 24px;
`;

const Wrap = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  width: 550px;
  height: 440px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;

  > p {
    padding-bottom: 16px;
    color: ${colors.GREEN_BASE};
    font-size: 12px;
  }
`;

const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span:nth-child(1) {
    font-size: 26px;
    font-family: 'GmarketSans_B';
    font-weight: 700;
  }

  > span:nth-child(2) {
    font-size: 18px;
    padding-top: 3px;
    font-family: 'Pretendard_L';
  }
`;

const InputWrap = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-top: 60px;
  padding-bottom: 38px;
`;

const EnterpriseWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  gap: 16px;
  > span {
    min-width: 50px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 16px;

    > span {
      font-size: 16px;
      font-weight: 500;
      min-width: 50px;
      text-align: left;
    }
  }
`;

const EnterpriseInfoWrap = styled.div<{ $hasCompanyId: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  > span:nth-child(1) {
    display: inline-block;
    width: 350px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: #7e899e;
    color: ${({ $hasCompanyId, theme }) =>
      $hasCompanyId ? theme.TEXT_PRIMARY : colors.NEUTRAl_300};
    font-family: 'Pretendard_R';
    font-size: 16px;
    height: 40px;
    border-radius: 8px;
    padding: 10px 24px;
  }
`;

const MyInput = styled.input`
  ${defaultInputCss};
  width: 350px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #7e899e;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  font-family: 'Pretendard_R';
  font-size: 16px;
  &::placeholder {
    color: ${colors.WHITE};
    opacity: 0.4;
    font-family: 'Pretendard_R';
  }
`;

const LoginBtn = styled.button`
  color: ${colors.BLACK};
  padding: 8px 40px;
  background: linear-gradient(0deg, #07d7a5 0%, #07d7a5 100%), #22232b;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 17px;
  font-weight: 600;
  margin-top: 16px;
`;

const SearchIcon = styled(SearchSVG)`
  position: absolute;
  width: 20px;
  height: 20px;
  cursor: pointer;
  top: 9px;
  right: 16px;
`;

export default Login;
