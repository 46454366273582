/* eslint-disable @typescript-eslint/no-explicit-any */
import { api, apiError, v2 } from './common';
import store from 'store/configureStore';

export const getCiImg = async () => {
  const companyId = store.getState().accountInfo.info.companyId;
  try {
    const { data } = await api.get(`companies/${companyId}/images`, {
      responseType: 'blob',
    });
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getNavInfo = async (companyId: number) => {
  try {
    const { data } = await api.get(`/companies/${companyId}/domains`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getOneCompany = async (companyId: number) => {
  try {
    const { data } = await api.get(`/companies/${companyId}`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const postEquipment = async (prms: FormData) => {
  const dashboardId = store.getState().pageInfo.dashboardId;
  try {
    const { data } = await api.patch(`/dashboards/${dashboardId}`, prms, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const putEquipment = async (prms: FormData) => {
  const dashboardId = store.getState().pageInfo.dashboardId;

  try {
    const { data } = await api.patch(`/dashboards/${dashboardId}`, prms, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getDashboardData = async (dashboardId: number) => {
  try {
    const { data } = await api.get(`${v2}/dashboards/${dashboardId}/details`);
    const {
      id,
      name,
      type,
      description,
      imagePath,
      activeColor,
      inactiveColor,
      disconnectedColor,
      dataIntegrationStatus,
      operatingStatus,
      // operatingSensorId,
      sensors,
      displaySensorIdOrders,
    } = data.data;
    const cvtData = {
      sensors,
      dashboard: {
        id,
        name,
        type,
        description,
        imagePath,
        disconnectedColor,
        dataIntegrationStatus,
        operatingStatus,
        activeColor,
        inactiveColor,
        displaySensorIdOrders,
      },
    };
    return cvtData;
  } catch (e: any) {
    const data = e.response;
    if (dashboardId !== -1) {
      apiError(data);
    }
  }
};

type filteredDashboard = {
  startDate: number;
  endDate: number;
  sensorId: number;
  dashboardId: number;
  timeInterval?: number;
  timeUnit?: string;
  aggregate?: string;
  filter?: boolean;
};

export const getFilteredDashboard = async (
  prms: filteredDashboard,
  isMain?: boolean,
) => {
  const { sensorId, dashboardId, startDate, endDate, filter = false } = prms;
  try {
    let cvtPrms = '';
    const keys = Object.keys(prms);
    keys.forEach((key) => {
      if (prms[key as keyof filteredDashboard]) {
        cvtPrms +=
          cvtPrms.length === 0
            ? `?${key}=${prms[key as keyof filteredDashboard]}`
            : `&${key}=${prms[key as keyof filteredDashboard]}`;
      }
    });
    const { data } = isMain
      ? await api.get(
          `/sensors/${sensorId}?startDate=${startDate}&endDate=${endDate}&filter=${filter}`,
        )
      : await api.get(`${v2}/sensors/${sensorId}${cvtPrms}`);
    return data;
  } catch (e: any) {
    const data = e.response;
    if (dashboardId !== -1) {
      apiError(data);
    }
  }
};

export const getSensorsAlarm = async (
  prms: filteredDashboard,
  isMain?: boolean,
) => {
  const { sensorId, dashboardId, startDate, endDate } = prms;
  try {
    let cvtPrms = '';
    const keys = Object.keys(prms);
    keys.forEach((key) => {
      if (prms[key as keyof filteredDashboard]) {
        cvtPrms +=
          cvtPrms.length === 0
            ? `?${key}=${prms[key as keyof filteredDashboard]}`
            : `&${key}=${prms[key as keyof filteredDashboard]}`;
      }
    });
    const { data } = isMain
      ? await api.get(
          `/sensors/${sensorId}/alarms?startDate=${startDate}&endDate=${endDate}`,
        )
      : await api.get(`${v2}/sensors/${sensorId}/alarms${cvtPrms}`);
    return data;
  } catch (e: any) {
    const data = e.response;
    if (dashboardId !== -1) {
      apiError(data);
    }
  }
};

export type postHidePrms = {
  hideSensors: number[];
};

export const postHideSensor = async (prms: postHidePrms) => {
  const dashboardId = store.getState().pageInfo.dashboardId;
  try {
    const { data } = await api.patch(
      `dashboards/${dashboardId}/sensors/hides`,
      prms,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const postChangeOrderSensors = async (prms: { orders: number[] }) => {
  const dashboardId = store.getState().pageInfo.dashboardId;
  try {
    const { data } = await api.post(`dashboards/${dashboardId}/settings`, prms);
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getEquipImg = async (dashboardId: number) => {
  try {
    const { data } = await api.get(`${v2}/dashboards/${dashboardId}/images`, {
      responseType: 'blob',
    });
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getMainDashboardData = async (companyId: number) => {
  try {
    const { data } = await api.get(
      `${v2}/companies/${companyId}/dashboards/status`,
    );
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getMainDashboardStatisticsTypes = async () => {
  try {
    const { data } = await api.get(`${v2}/dashboards/statistics/types`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getMainDashboardStatistics = async (prms: any) => {
  const { startDate, endDate, statisticsType } = prms;
  const companyId = store.getState().accountInfo.info.companyId;

  try {
    const { data } = await api.get(
      `${v2}/companies/${companyId}/dashboards/statistics?startDate=${startDate}&endDate=${endDate}&statisticsType=${statisticsType}`,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const patchIntegrationToggle = async () => {
  const dashboardId = store.getState().pageInfo.dashboardId;
  try {
    const { data } = await api.patch(
      `${v2}/dashboards/${dashboardId}/toggle-data-integration`,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};
