import React, { useEffect, SetStateAction, useState } from 'react';
import { styled } from 'styled-components';
import { colors } from 'constants/colors';
import Tag from 'components/Tag/Tag';
import { floatingAlarm } from 'types/alarm-T';
import AlarmDetailModal from './AlarmDetailModal';
import { INIT_ALARM_DETAIL } from 'services/init-data';
import { getAlarmDetail } from 'apis/alarm-rest';

interface IProps {
  data: floatingAlarm;
  setNoticeCount: React.Dispatch<SetStateAction<number>>;
}

const FloatingAlarm: React.FC<IProps> = ({ data, setNoticeCount }) => {
  const [onDetailModal, setOnDetailModal] = useState(false);
  const [detailData, setDetailData] = useState(INIT_ALARM_DETAIL);
  const [isEnteredContent, setIsEnteredContent] = useState(false);

  useEffect(() => {
    setNoticeCount((prev) => prev + 1);

    return () => setNoticeCount((prev) => prev - 1);
  }, []);

  useEffect(() => {
    getAlarmDetail(data.id).then((res) => {
      setDetailData(res);
    });
  }, []);

  useEffect(() => {
    if (detailData.checks.length > 0) {
      setOnDetailModal(false);
    }
  }, [detailData]);

  return (
    <Wrap>
      <ContentWrap>
        <Table>
          <Tbody>
            <TableTr>
              <TableTh>알람 종류</TableTh>
              <TableTd>
                <Tag text={detailData.type} />
              </TableTd>
            </TableTr>
            <TableTr>
              <TableTh>알람명</TableTh>
              <TableTd>
                <Tag
                  color={detailData.alarmCaseColor}
                  text={detailData.alarmCaseName}
                />
              </TableTd>
            </TableTr>
            <TableTr>
              <TableTh>설비명</TableTh>
              <TableTd>{detailData.dashboardName}</TableTd>
            </TableTr>
            <TableTr>
              <TableTh>발생 위치</TableTh>
              <TableTd>{detailData.sensor.name}</TableTd>
            </TableTr>
            <TableTr>
              <TableTh>내용</TableTh>
              <TableTd>{detailData.content}</TableTd>
            </TableTr>
            <TableTr>
              <TableTh>발생일</TableTh>
              <TableTd>{detailData.createdTime}</TableTd>
            </TableTr>
            <TableTr>
              <TableTh>접수자</TableTh>
              <TableTd>{data.receiverName}</TableTd>
            </TableTr>
          </Tbody>
        </Table>
        {!isEnteredContent && (
          <ActionBtn onClick={() => setOnDetailModal(true)}>
            알람 조치
          </ActionBtn>
        )}
        {isEnteredContent && (
          <ActionBtn disabled $isFinish>
            조치 완료
          </ActionBtn>
        )}
      </ContentWrap>
      {onDetailModal && (
        <AlarmDetailModal
          data={detailData}
          onClose={() => setOnDetailModal(false)}
          refetch={() => setIsEnteredContent(true)}
        />
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 450px;
  z-index: 10000;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
`;

const ContentWrap = styled.div``;

const Table = styled.table`
  margin-left: -36px;
`;

const Tbody = styled.tbody``;

const TableTr = styled.tr`
  font-size: 14px;
`;

const TableTh = styled.th`
  padding: 7px 0;
  text-align: left;
  vertical-align: middle;
  width: 90px;
  font-weight: 700;
`;

const TableTd = styled.td`
  padding: 2px 0;
  text-align: left;
  vertical-align: middle;
  font-weight: 500;
  width: 240px;
`;

const ActionBtn = styled.button<{ $isFinish?: boolean }>`
  margin-top: 14px;
  margin-left: -36px;
  width: 75%;
  height: 24px;
  border-radius: 4px;
  background-color: ${({ $isFinish }) =>
    $isFinish ? 'transparent' : colors.GREEN_BASE};
  border: none;
  color: ${({ $isFinish }) => ($isFinish ? colors.GREEN_BASE : colors.BLACK)};
  font-weight: 600;
`;

export default FloatingAlarm;
