import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './constants/font-face.css';
import App from './App';
import Provider from 'provider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider>
    <App />
  </Provider>,
);
