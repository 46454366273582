import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { SUB } from 'services/enum';

interface State {
  dashboardId: number;
  collapsedIds: number[];
  clickedNav: boolean;
  subNavIdx: number;
  saveSensorIdList: number[];
  onSnackbar: boolean;
}

const initialState: State = {
  dashboardId: -1,
  collapsedIds: [],
  clickedNav: false,
  subNavIdx: SUB.SENSOR_TABLE,
  saveSensorIdList: [],
  onSnackbar: false,
};

const pageInfoSlice = createSlice({
  name: 'pageInfo',
  initialState,
  reducers: {
    setDashboardId: (state, action: PayloadAction<number>) => {
      state.dashboardId = action.payload;
    },
    setPageInfoReset: (state) => {
      return initialState;
    },
    setClickedNav: (state, action: PayloadAction<boolean>) => {
      state.clickedNav = action.payload;
    },
    setCollapsedIds: (state, action: PayloadAction<number[]>) => {
      state.collapsedIds = action.payload;
    },
    setSubNavIdx: (state, action: PayloadAction<number>) => {
      state.subNavIdx = action.payload;
    },
    setAddSensorId: (state, action: PayloadAction<number>) => {
      const set = new Set([...state.saveSensorIdList, action.payload]);

      state.saveSensorIdList = Array.from(set);
    },
    setRemoveSensorId: (state, action: PayloadAction<number>) => {
      const set = new Set([...state.saveSensorIdList]);
      set.delete(action.payload);

      state.saveSensorIdList = Array.from(set);
    },
    setOnSnackbar: (state, action: PayloadAction<boolean>) => {
      state.onSnackbar = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const {
  setDashboardId,
  setPageInfoReset,
  setCollapsedIds,
  setClickedNav,
  setSubNavIdx,
  setAddSensorId,
  setRemoveSensorId,
  setOnSnackbar,
} = pageInfoSlice.actions;
export default pageInfoSlice.reducer;
