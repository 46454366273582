import { useValidSimpleSensor } from 'apiHooks/useSensor';
import React, { ChangeEvent, useState } from 'react';

const useFileUpload = () => {
  const [sensorData, setSensorData] = useState([]);
  const [formData, setFormData] = useState(new FormData());
  const [filename, setFilename] = useState('');
  const { mutateAsync: mutateValidAsync } = useValidSimpleSensor();

  const originClickInputFile = async (
    e: ChangeEvent<HTMLInputElement>,
    prmName: string,
  ) => {
    const target = e.currentTarget;
    const files = (target.files as FileList)[0];
    const { size, name } = files;
    setFilename(name);
    const newFormData = new FormData();
    newFormData.append(prmName, files, name);
    setFormData(newFormData);
  };
  // 1. blob 데이터 전송 방법
  // new Blob([]) 첨부파일 삭제
  // attachment 안보내면 유지
  // 보내면 추가

  const handleClickInputFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    const files = (target.files as FileList)[0];
    const { size, name } = files;

    setFilename(name);

    const newFormData = new FormData();
    newFormData.append('sensorsFile', files);
    const isValid = await handleClickValid(newFormData);
    if (isValid) {
      setFormData(newFormData);
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    // setFormData(new FormData());
    const newFormData = new FormData();
    const files = e.dataTransfer.files[0];
    if (e.dataTransfer.files.length > 0) {
      newFormData.append('sensorsFile', files);
      const isValid = await handleClickValid(newFormData);
      if (isValid) {
        setFormData(newFormData);
      }
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
  };

  const handleClickValid = async (formData: FormData) => {
    const res = await mutateValidAsync(formData);
    if (res?.success) {
      setSensorData(res.data);
      return true;
    }
    return false;
  };

  const handleClickDeleteFile = () => {
    setFilename('');
    setFormData(new FormData());
  };

  return {
    sensorData,
    formData,
    filename,
    setFormData: setFormData,
    originClickInputFile: originClickInputFile,
    onClickInputFile: handleClickInputFile,
    resetFormData: handleClickDeleteFile,
    handleDrop,
    handleDragOver,
  };
};

export default useFileUpload;
