/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import GlobalCss from './services/GlobalCss';
import routePath from './constants/route';
import { styled } from 'styled-components';
import ErrorModal from 'components/ModalContents/ErrorModal';
import CompleteModal from 'components/ModalContents/CompleteModal';
import './App.css';
import SSEContainer from 'components/common/SSEContainer';
import Login from 'pages/Login';
import AlarmCheckLogin from 'pages/m/AlarmCheckLogin';
import AlarmReceive from 'pages/m/AlarmReceive';
import { useAppSelect } from 'store/configureStore.hooks';
import MySnackbar from 'components/Snackbar/Snackbar';
// const Login = lazy(() => import('pages/Login'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const Main = lazy(() => import('pages/Main'));
const NotFound = lazy(() => import('pages/NotFound'));
const AccountManage = lazy(() => import('pages/AccountManage'));

const App = () => {
  const { onSnackbar } = useAppSelect((state) => state.pageInfo);
  return (
    <Wrap>
      <SSEContainer>
        <GlobalCss />
        <BrowserRouter>
          <Suspense fallback={<div />}>
            <Routes>
              <Route path={routePath.LOGIN} element={<Login />} />
              <Route path={routePath.DASHBOARD} element={<Dashboard />} />
              <Route path={routePath.MAIN} element={<Main />} />
              <Route
                path={routePath.ACCOUNT_MANAGE}
                element={<AccountManage />}
              />
              <Route
                path={routePath.ALARM_CHECK}
                element={<AlarmCheckLogin />}
              />
              <Route
                path={routePath.ALARM_RECEIVE}
                element={<AlarmReceive />}
              />
              <Route path={routePath.NOT_FOUND} element={<NotFound />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </SSEContainer>

      <ErrorModal />
      <CompleteModal />
      {onSnackbar && (
        <MySnackbar
          onSnackbar={onSnackbar}
          content="파일 다운로드를 완료하였습니다."
        />
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  background-color: ${({ theme }) => theme.ROOT_BG};
  min-width: 1920px;
  /* max-width: 1920px; */
`;

export default App;
