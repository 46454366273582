import Modal from 'components/common/Modal';
import React from 'react';
import { styled } from 'styled-components';
import { ReactComponent as exclamationSVG } from 'assets/IEMS icon/sensor/error-circle.svg';
import { colors } from 'constants/colors';
import ModalTitle from './common/ModalTitle';
import { useAppDispatch, useAppSelect } from 'store/configureStore.hooks';
import { setResetError } from 'store/modules/error';

const ErrorModal = () => {
  const dispatch = useAppDispatch();
  const { isError, error } = useAppSelect((state) => state.errorData.data);

  const code = error.code;
  const text = error.message;
  const handleClose = () => {
    dispatch(setResetError());
  };

  if (!isError) return null;

  return (
    <Modal onClose={handleClose}>
      <Wrap>
        <ModalTitle title="에러 발생" onClose={handleClose} />
        <XcircleIcon />
        <CodeWrap>
          {code?.length !== 0 && <p>{`Error Code: ${code} `}</p>}
        </CodeWrap>
        <ErrorWrap>
          <p>{text}</p>
        </ErrorWrap>
        <ModalBtn onClick={handleClose}>확인</ModalBtn>
      </Wrap>
    </Modal>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  width: 450px;
  padding: 24px;
  border-radius: 12px;
  gap: 36px;
  z-index: 2000;

  @media (max-width: 768px) {
    width: 300px;
    padding: 16px;
    gap: 36px;
  }
`;

const XcircleIcon = styled(exclamationSVG)`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

const CodeWrap = styled.div`
  margin: 0 auto;
  margin-top: -20px;
  > p {
    font-size: 13.5px;
    color: ${({ theme }) => theme.TEXT_PRIMARY}99;
  }
`;

const ErrorWrap = styled.div`
  margin: 0 auto;
  > p {
    font-size: 14px;
    color: ${({ theme }) => theme.TEXT_PRIMARY};
    line-height: 22px;
    text-align: center;
    white-space: pre-line;
  }
`;

const ModalBtn = styled.button`
  padding: 8px 46px;
  align-self: center;
  color: ${colors.BLACK};
  background: ${colors.GREEN_BASE};
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  border: 0;
  outline: 0;
  font-weight: 600;
  font-size: 16px;
`;

export default ErrorModal;
