/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from 'components/common/Modal';
import React from 'react';
import styled from 'styled-components';
import { getDefaultWrap } from './common/modalCss';
import AlarmConfirmMessage from 'components/AlarmConfirmMessage/AlarmConfirmMessage';
import ModalTitle from './common/ModalTitle';
import { colors } from 'constants/colors';
interface IProps {
  data: any;
  detailData: any;
  isModify: boolean;
  detailRefetch: () => void;
  refetch?: () => void;
  onClose: () => void;
}
const AlarmConfirmMessageModal: React.FC<IProps> = ({
  data,
  detailData,
  isModify,
  detailRefetch,
  refetch,
  onClose,
}) => {
  return (
    <Modal>
      <Wrap>
        <ModalTitle
          title={`조치 내용 ${isModify ? '수정' : '등록'}`}
          onClose={onClose}
          fillColor={colors.WHITE}
          textColor={colors.WHITE}
        />
        <AlarmConfirmMessage
          data={data}
          detailData={detailData}
          isModify={isModify}
          detailRefetch={detailRefetch}
          refetch={refetch}
          onClose={onClose}
        />
      </Wrap>
    </Modal>
  );
};

const Wrap = styled.div`
  ${getDefaultWrap};
  width: 700px;
`;

export default AlarmConfirmMessageModal;
