import React from 'react';
import { styled, css } from 'styled-components';
import { colors } from '../../../constants/colors';
import LoadingSpinner from 'components/common/LoadingSpinner';
interface IProps {
  btnText: string;
  btnWidth?: number;
  position?: string;
  isLoading?: boolean;
  isValid?: boolean;
  children?: React.ReactNode;
  onClose: () => void;
  onClick?: () => void;
}

const ModalButton: React.FC<IProps> = ({
  btnText,
  btnWidth,
  position,
  isLoading,
  children,
  onClose,
  onClick,
  isValid = true,
}) => {
  return (
    <Wrap $position={position}>
      {children}
      <MyButton
        type="button"
        $btnWidth={btnWidth}
        onClick={onClose}
        disabled={isLoading}
      >
        닫기
      </MyButton>
      <MyButton
        type="submit"
        $btnWidth={btnWidth}
        $isFill
        onClick={onClick}
        disabled={!isValid}
      >
        {isLoading ? <LoadingSpinner size={14} /> : btnText}
      </MyButton>
    </Wrap>
  );
};

export const getBtnStyle = ($isFill?: boolean) => {
  if ($isFill) {
    return css`
      background-color: ${colors.GREEN_BASE};
      color: ${colors.BLACK};
      border: 1px solid rgba(255, 255, 255, 0.5);
    `;
  }
  return css`
    background-color: ${colors.BLACK};
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: ${colors.GREEN_BASE};
  `;
};

const Wrap = styled.div<{ $position?: string }>`
  display: flex;
  gap: 24px;
  justify-content: ${({ $position }) => ($position ? $position : 'center')};
  width: 100%;
`;

export const MyButton = styled.button<{
  $btnWidth?: number;
  $isFill?: boolean;
  disabled?: boolean;
}>`
  border-radius: 4px;
  /* border: 0; */
  outline: 0;
  /* width: ${({ $btnWidth }) => $btnWidth}px; */
  padding: 8px 46px;
  ${({ $isFill }) => getBtnStyle($isFill)};
  font-family: 'Pretendard_B';
  font-size: 16px;
`;

export default ModalButton;
