import React from 'react';
import { styled } from 'styled-components';
import { ReactComponent as DeleteSVG } from 'assets/img/delete.svg';
import { colors } from 'constants/colors';

interface IProps {
  onClose: () => void;
  textColor?: string;
  fillColor?: string;
  title?: string;
  children?: React.ReactNode;
}
const ModalTitle: React.FC<IProps> = ({
  title,
  textColor,
  fillColor,
  children,
  onClose,
}) => {
  return (
    <HeaderWrap $textColor={textColor}>
      {children}
      <span>{title}</span>
      <DeleteIcon $fillColor={fillColor} onClick={onClose} />
    </HeaderWrap>
  );
};

const HeaderWrap = styled.div<{ $textColor?: string }>`
  display: flex;
  justify-content: space-between;
  font-family: 'GmarketSans_R';
  font-size: 19px;
  align-items: center;
  color: ${({ $textColor, theme }) =>
    $textColor ? $textColor : theme.TEXT_PRIMARY};

  > img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const DeleteIcon = styled(DeleteSVG)<{ $fillColor?: string }>`
  width: 20px;
  height: 20px;
  cursor: pointer;
  fill: ${({ $fillColor, theme }) =>
    $fillColor ? $fillColor : theme.TEXT_PRIMARY};
`;

export default ModalTitle;
