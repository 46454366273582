import Tag from 'components/Tag/Tag';
import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components';
import { alarmDetail } from 'types/alarm-T';
interface IProps {
  data: alarmDetail;
}
const M_alarmBasicInfo: React.FC<IProps> = ({ data }) => {
  return (
    <Table>
      <tbody>
        <tr>
          <th>설비명</th>
          <td>{data.dashboardName}</td>
        </tr>
        <tr>
          <th>발생 위치</th>
          <td>{data.sensor.name}</td>
        </tr>
        <tr>
          <th>알람명</th>
          <td>
            <Tag text={data.alarmCaseName} color={data.alarmCaseColor} />
          </td>
        </tr>
        <tr>
          <th>발생일</th>
          <td>{data.createdTime}</td>
        </tr>
        <tr>
          <th>알람 범위</th>
          <td colSpan={3}>
            {data.ranges.map((item, idx: number) => {
              const lower = item.lower === '-Infinity' ? '- ∞' : item.lower;
              const upper = item.upper === 'Infinity' ? '+ ∞' : item.upper;
              return (
                <RangeWrap key={String(idx)}>{`${lower} ~ ${upper}`}</RangeWrap>
              );
            })}
          </td>
        </tr>
        <tr>
          <th>알람 내용</th>
          <td>{data.content}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  position: relative;
  width: 100%;
  th {
    background: #6c6c6c;
    color: ${({ theme }) => theme.WHITE};
    width: 80px;
    height: 43px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 500;
  }
  tr {
    border-top: 1px solid #9a9a9a;
    border-bottom: 1px solid #9a9a9a;
  }
  td {
    vertical-align: middle;
    padding-left: 20px;
    font-size: 14px;
  }
`;

const RangeWrap = styled.span`
  font-size: 14px;
  background-color: ${colors.NEUTRAl_400};
  color: ${colors.WHITE};
  padding: 4px 12px;
  border-radius: 8px;
  margin-right: 6px;
`;

export default M_alarmBasicInfo;
