/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutationEnteredContent } from 'apiHooks/useAlarm';
import { apiCompleted } from 'apis/common';
import { colors } from 'constants/colors';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { customErrorMsg } from 'services/miscFunc';
import { useAppSelect } from 'store/configureStore.hooks';
import { accountInfo } from 'store/modules/account';
import styled from 'styled-components';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import useFileUpload from 'hooks/useFileUpload';
import ModalButton from 'components/ModalContents/common/ModalButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Tooltip, Typography } from '@mui/material';
interface IProps {
  detailData: any;
  detailRefetch: () => void;
  onClose: () => void;
  isModify: boolean;
}
const M_AlarmConfirmMessage: React.FC<IProps> = ({
  isModify,
  detailData,
  detailRefetch,
  onClose,
}) => {
  const { mutateAsync, isLoading } = useMutationEnteredContent(detailData.id);
  const accountInfo: accountInfo = useAppSelect(
    (state) => state.accountInfo.info,
  );
  const {
    originClickInputFile,
    filename,
    formData,
    setFormData,
    resetFormData,
  } = useFileUpload();

  const [isAlreadyFile, setIsAlreadyFile] = useState(
    detailData.checks[0]?.attachmentPath &&
      detailData.checks[0]?.attachmentName,
  );
  const [textareaValue, setTextareaValue] = useState(
    isModify ? detailData.checks[0].message : '',
  );

  const { accountName: name, loginId, role } = accountInfo;

  const handleClickRemoveFile = () => {
    resetFormData();
    setIsAlreadyFile(false);
    const newFormData = new FormData();
    newFormData.append('attachment', new Blob([]));
    newFormData.forEach((value, key) => {
      console.log(key, value);
    });
    setFormData(newFormData);
  };

  const handleClickEnteredContent = async () => {
    if (textareaValue && textareaValue.length <= 3) {
      customErrorMsg('내용을 4자 이상 등록해주세요.');
      return;
    }
    const isAuthEntered = !(
      role === 'MASTER' || detailData.receiveInfo.receiverLoginId === loginId
    );
    if (isAuthEntered) {
      customErrorMsg('조치 권한이 없습니다. 관리자에게 문의해주세요.');
      return;
    }

    const enteredPrms = {
      message: textareaValue,
    };

    formData.append(
      'request',
      new Blob([JSON.stringify(enteredPrms)], {
        type: 'application/json',
      }),
    );

    const res = await mutateAsync(formData);
    if (!res?.success) {
      setFormData(new FormData());
    }

    if (res?.success) {
      apiCompleted();
      detailRefetch();
      onClose();
    }
  };

  return (
    <>
      <MessageTable>
        <tbody>
          <tr>
            <th>등록자</th>
            <td>{`${name}(${loginId})`}</td>
          </tr>
          <tr>
            <th>첨부 파일</th>
            <td>
              {isAlreadyFile ? (
                detailData.checks[0].attachmentName
              ) : filename ? (
                filename
              ) : (
                <FileLabel htmlFor="fileInput">
                  <FileUploadIcon />
                  파일 업로드
                </FileLabel>
              )}
              {(isAlreadyFile || filename) && (
                <Tooltip
                  arrow
                  title={
                    <Typography fontSize={14} fontWeight={600}>
                      첨부 파일 삭제
                    </Typography>
                  }
                >
                  <DeleteIcon onClick={handleClickRemoveFile} />
                </Tooltip>
              )}
              <FileInput
                id="fileInput"
                type="file"
                onChange={(e) => originClickInputFile(e, 'attachment')}
              />
            </td>
          </tr>
          <tr>
            <th>조치 내용</th>
            <td colSpan={3}>
              <ContentWrap>
                <textarea
                  value={textareaValue}
                  onChange={(e) => setTextareaValue(e.target.value)}
                />
              </ContentWrap>
            </td>
          </tr>
        </tbody>
      </MessageTable>
      <ModalButton
        btnText={isModify ? '수정' : '등록'}
        onClose={onClose}
        isLoading={isLoading}
        onClick={handleClickEnteredContent}
      />
    </>
  );
};

const MessageTable = styled.table`
  position: relative;
  width: 100%;
  th {
    background: #6c6c6c;
    color: ${({ theme }) => theme.WHITE};
    width: 80px;
    height: 43px;
    font-size: 14px;
    font-weight: 500;
    vertical-align: middle;
  }
  tr {
    border-top: 1px solid #9a9a9a;
    border-bottom: 1px solid #9a9a9a;
  }
  td {
    position: relative;
    vertical-align: middle;
    padding-left: 10px;
    font-size: 14px;
  }
  tr:nth-child(1) > td:nth-child(2) {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 20px;
    white-space: pre-wrap;
    word-break: break-all;
  }
  tr:nth-child(2) {
  }
  textarea {
    width: 95%;
    height: 70px;
    padding: 8px 16px;
    margin: 4px 0;
  }
`;

const ContentWrap = styled.div`
  max-width: 100%;
  display: flex;
  gap: 20px;
`;

const ModalSaveBtn = styled.button`
  width: 80px;
  height: 60px;
  background: ${colors.GREEN_BASE};
  color: ${colors.BLACK};
  border-radius: 6px;
  border: 0;
  outline: 0;
  font-weight: 500;
`;

const FileLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 95%;
  padding: 4px 0;
  cursor: pointer;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  background: ${colors.NEUTRAl_400};
`;

const FileInput = styled.input`
  display: none;
`;

const DeleteIcon = styled(RemoveCircleIcon)`
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  fill: ${colors.NEUTRAl_300};
  cursor: pointer;
`;

export default M_AlarmConfirmMessage;
