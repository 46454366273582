/* eslint-disable @typescript-eslint/no-explicit-any */
import store from 'store/configureStore';
import { api, apiError, v2 } from './common';
import { changeSensorInfo, sensorInfoT } from 'types/sensor-T';
import { IColorDatas, IDatas, IOperatingSensors } from 'apiHooks/useSensor';

export const getTagType = async () => {
  try {
    const { data } = await api.get(`/tag-types`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getDisplayType = async () => {
  try {
    const { data } = await api.get(`/display-types/codes`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const postSensor = async (prms: FormData) => {
  const dashboardId = store.getState().pageInfo.dashboardId;
  try {
    const { data } = await api.post(
      `${v2}/dashboards/${dashboardId}/sensors`,
      prms,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const deleteSensor = async (id: number) => {
  try {
    const { data } = await api.delete(`/sensors/${id}`);
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getDevice = async () => {
  try {
    const { data } = await api.get(`/devices/codes`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getSensorType = async (deviceId: number) => {
  const companyId = store.getState().accountInfo.info.companyId;
  try {
    const { data } = await api.get(
      `/${companyId}/sensorType?deviceId=${deviceId}`,
    );
    return data.data.sensorTypeList;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const putSensor = async (prms: changeSensorInfo) => {
  const { id, name, unit, description } = prms;
  const cvtPrms = { name, unit, description };
  try {
    const { data } = await api.patch(`/sensors/${id}`, cvtPrms);
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const validationScript = async (prms: FormData) => {
  const dashboardId = store.getState().pageInfo.dashboardId;
  try {
    const { data } = await api.post(
      `/dashboards/${dashboardId}/scripts/validations`,
      prms,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const postAddSimpleSensor = async (prms: FormData) => {
  const dashboardId = store.getState().pageInfo.dashboardId;
  try {
    const { data } = await api.post(
      `${v2}/dashboards/${dashboardId}/sensors-file`,
      prms,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const postAddSimpleValidSensor = async (prms: FormData) => {
  try {
    const { data } = await api.post(
      `${v2}/dashboards/sensors-file/validate`,
      prms,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getTimeUnitTypes = async () => {
  try {
    const { data } = await api.get(`${v2}/sensors/time-unit-types`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getAggregateTypes = async () => {
  try {
    const { data } = await api.get(`${v2}/sensors/aggregate-types`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getSensorExcel = async (prms: IDatas) => {
  const isOnIntervalOption = prms.intervalOption === 'on';
  const baseUrl = isOnIntervalOption
    ? `${v2}/sensors/${prms.selectedSensor}`
    : `/sensors/${prms.selectedSensor}`;

  const queryParams = new URLSearchParams();

  // 공통 파라미터
  queryParams.append('startDate', prms.startDate.toString());
  queryParams.append('export', prms.export);
  queryParams.append('endDate', prms.endDate.toString());

  if (isOnIntervalOption) {
    if (prms.timeInterval !== undefined) {
      queryParams.append('timeInterval', prms.timeInterval.toString());
    }
    if (prms.timeUnit) {
      queryParams.append('timeUnit', prms.timeUnit);
    }
    if (prms.aggregate) {
      queryParams.append('aggregate', prms.aggregate);
    }
  }

  try {
    const { data } = await api.get(`${baseUrl}?${queryParams.toString()}`);
    return {
      success: data.success,
    };
  } catch (e: any) {
    const data = e.response;
    apiError(data);
    return {
      success: false,
    };
  }
};

export const putOperatingSensors = async ({
  operatingPrms,
  pollutionPrms,
  dashboardId,
}: {
  operatingPrms: Partial<IOperatingSensors>;
  pollutionPrms: Partial<IOperatingSensors>;
  dashboardId: number;
}) => {
  const companyId = store.getState().accountInfo.info.companyId;
  const cvtPrms = [pollutionPrms, operatingPrms];
  try {
    const { data } = await api.put(
      `${v2}/companies/${companyId}/dashboards/${dashboardId}/operating-sensors`,
      cvtPrms,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const putDashboardSettings = async (prms: IColorDatas) => {
  const companyId = store.getState().accountInfo.info.companyId;

  const { activeColor, inactiveColor, disconnectedColor } = prms;
  const cvtPrms = { activeColor, inactiveColor, disconnectedColor };
  try {
    const { data } = await api.put(
      `/companies/${companyId}/dashboards/settings`,
      cvtPrms,
    );
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getMappingInfos = async (sensorId: number) => {
  try {
    const { data } = await api.get(`${v2}/sensors/${sensorId}/mapping-infos`);
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};
