import Modal from 'components/common/Modal';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { getDefaultWrap } from './modalCss';
import { colors } from 'constants/colors';
import ModalButton from './ModalButton';
interface IProps {
  isAccountInfo?: boolean;
  password?: string;
  newPassword: string;
  confirmPassword: string;
  onClick: () => void;
  onClose: () => void;
  onChangePw: (e: ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
}

const ChangeNewPw: React.FC<IProps> = ({
  isAccountInfo,
  children,
  onClick,
  onClose,
  onChangePw,
}) => {
  //TODO: INPUT 정보는 부모 컴포넌트가 받기
  return (
    <Modal>
      <Wrap>
        {children}
        <PasswordWrap>
          {isAccountInfo && (
            <MyInput
              name="password"
              type="password"
              placeholder="기존 비밀번호"
              autoComplete="off"
              onChange={onChangePw}
            />
          )}
          <MyInput
            name="newPassword"
            type="password"
            placeholder="새로운 비밀번호"
            autoComplete="off"
            onChange={onChangePw}
          />
          <MyInput
            name="confirmPassword"
            type="password"
            placeholder="비밀번호 확인"
            autoComplete="off"
            onChange={onChangePw}
          />
        </PasswordWrap>
        <ValidationP>
          *특수문자, 영문, 숫자를 포함하여 8글자 이상 16글자 이하를
          입력해주세요.
        </ValidationP>
        <ModalButton
          btnText="변경 완료"
          btnWidth={210}
          onClick={onClick}
          onClose={onClose}
        />
      </Wrap>
    </Modal>
  );
};

const Wrap = styled.div`
  ${getDefaultWrap};
  width: 450px;
`;

const PasswordWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ValidationP = styled.p`
  padding-bottom: 8px;
  color: ${colors.GREEN_BASE};
  font-size: 12px;
  font-weight: 600;
`;

const MyInput = styled.input`
  width: 85%;
  height: 40px;
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.TEXT_PRIMARY}55;
  background: none;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  padding: 4px 12px;
  font-size: 14px;

  &:focus {
    border-bottom: 2px solid ${colors.GREEN_BASE};
    transition: 0.7s;
  }

  &::placeholder {
    color: ${({ theme }) => theme.TEXT_PRIMARY}66;
    font-size: 12px;
  }
`;

export default ChangeNewPw;
