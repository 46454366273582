/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';
import store from 'store/configureStore';
import { setAccessToken, setAccountInfoReset } from 'store/modules/account';
import { updateAccessToken } from './auth-rest';
import { setPageInfoReset } from 'store/modules/pageInfo';
import routePath from 'constants/route';
import { setErrorData } from 'store/modules/error';
import { customConfirm, customError, cvtServerError } from 'services/miscFunc';
import { setConfirmData } from 'store/modules/confirm';

let isApiCallAllowed = true;

export const v2 = '/v2';

export const resetReduxInfo = () => {
  store.dispatch(setAccountInfoReset());
  store.dispatch(setPageInfoReset());
};

const axiosConfig: AxiosRequestConfig<any> = {
  baseURL: process.env.REACT_APP_BE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

const api = axios.create(axiosConfig);

api.interceptors.request.use(
  (config: any) => {
    if (!isApiCallAllowed) {
      return Promise.reject(new Error('계정 로그인 시간이 만료되었습니다.'));
    }

    const accessToken = store.getState().accountInfo.token.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401) {
      // 액세스 토큰이 없을 때: 로그인 하지 않은 상태
      const tokenInfo = store.getState().accountInfo.token;
      if (!tokenInfo.accessToken) {
        window.location.replace(process.env.REACT_APP_FE_URL as string);
        return Promise.reject(error);
      }
      // 액세스토큰 갱신 및 리프레시 토큰 만료
      const errorcode = error.response.data.error.code;
      if (errorcode === 'REFRESH_TOKEN_EXPIRED') {
        isApiCallAllowed = false;
        window.location.replace(process.env.REACT_APP_FE_URL as string);
        store.dispatch(setErrorData(error.response.data));
        return;
      }

      if (errorcode === 'ACCESS_TOKEN_EXPIRED') {
        const res = await updateAccessToken(tokenInfo);
        if (res?.success) {
          // 갱신
          const newAccessToken = res.data.accessToken;
          store.dispatch(setAccessToken(newAccessToken));
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return api(originalRequest);
        }
        window.location.replace(process.env.REACT_APP_FE_URL as string);
        store.dispatch(setErrorData(error.response.data));
        return;
      }

      if (errorcode === 'INVALID_ACCESS_KEY') {
        window.location.replace(process.env.REACT_APP_FE_URL as string);
        store.dispatch(setErrorData(error.response.data));
        return;
      }
    }

    if (error.response?.status === 404) {
      window.location.replace(
        `${process.env.REACT_APP_FE_URL}${routePath.NOT_FOUND}` as string,
      );
    }
    return Promise.reject(error);
  },
);

// Catch문 에러 처리
const apiError = (errData: any) => {
  console.log(errData);
  if (errData?.data?.timestamp) {
    // 서버에서 처리 안 된 에러
    const errorData = {
      success: false,
      error: {
        code: errData.data.status,
        message: cvtServerError(errData.data.status),
      },
    };
    store.dispatch(setErrorData(errorData));
    return;
  }
  if (errData?.data) {
    store.dispatch(setErrorData(errData.data));
  }
};

const apiCompleted = (msg?: string) => {
  store.dispatch(
    setConfirmData(customConfirm(msg ? msg : '요청이 적용되었습니다.')),
  );
};

export { api, apiError, apiCompleted };
