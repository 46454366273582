/* eslint-disable @typescript-eslint/no-explicit-any */
import { api, apiError } from './common';

export const updateAccessToken = async (tokenInfo: {
  accessToken: string;
  refreshToken: string;
}) => {
  const { accessToken, refreshToken } = tokenInfo;
  try {
    const { data } = await api.post('/auth/refresh', {
      accessToken,
      refreshToken,
    });
    return data;
  } catch (e: any) {
    const data = e.response;
    window.location.replace(process.env.REACT_APP_FE_URL as string);
    apiError(data);
  }
};

export const decodeToken = async (token: string) => {
  try {
    const { data } = await api.get(`/alarm-token?token=${token}`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};
