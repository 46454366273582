/* eslint-disable @typescript-eslint/no-explicit-any */
import { account } from 'types/account-T';
import { api, apiError } from './common';
import store from 'store/configureStore';

export const postLogin = async (prms: {
  loginId: string;
  password: string;
}) => {
  try {
    const { data } = await api.post(`/auth/login`, prms);
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const postEmailCode = async (prms: { email: string }) => {
  try {
    const { data } = await api.post(`/accounts/email-code`, prms);
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const postEmailVerify = async (prms: {
  email: string;
  emailCode: string;
}) => {
  try {
    const { data } = await api.post(`/accounts/verify-email-code`, prms);
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getAccountList = async () => {
  const companyId = store.getState().accountInfo.info.companyId;
  try {
    const { data } = await api.get(`/companies/${companyId}/accounts`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const postAccount = async (prms: account) => {
  const companyId = store.getState().accountInfo.info.companyId;
  try {
    const { data } = await api.post(`/companies/${companyId}/accounts`, prms);
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const putAccount = async (
  id: number,
  prms: Omit<account, 'loginId'>,
) => {
  try {
    const { data } = await api.patch(`/accounts/${id}`, prms);
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const deleteAccount = async (accountId: number) => {
  try {
    const { data } = await api.delete(`/accounts/${accountId}`);
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const getCompanyList = async () => {
  // const companyId = store.getState().accountInfo.info.companyId;
  try {
    const { data } = await api.get(`/companies/codes`);
    return data.data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};

export const pwCheck = async (prms: { id: number; password: string }) => {
  const companyId = store.getState().accountInfo.info.companyId;
  try {
    const { data } = await api.post(`/accounts/${prms.id}/password-checks`, {
      password: prms.password,
    });
    return {
      status: data.success,
    };
  } catch (e: any) {
    const data = e.response;
    return {
      status: data.data.success,
      message: data.data.message,
    };
  }
};

export const patchPw = async (prms: { id: number; password: string }) => {
  try {
    const { data } = await api.patch(`/accounts/${prms.id}/password-changes`, {
      password: prms.password,
    });
    return data;
  } catch (e: any) {
    const data = e.response;
    apiError(data);
  }
};
