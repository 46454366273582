/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SetStateAction } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getFilteredDashboard,
  getSensorsAlarm,
  postHidePrms,
  postHideSensor,
} from 'apis/dashboard-rest';
import {
  deleteSensor,
  getAggregateTypes,
  getDevice,
  getDisplayType,
  getTagType,
  getTimeUnitTypes,
  postAddSimpleSensor,
  postAddSimpleValidSensor,
  putSensor,
} from 'apis/sensor-rest';
import { queryKeys } from 'services/queryKeys';
import { changeSensorInfo, displayType } from 'types/sensor-T';
import { patchDisplayOrders } from 'apis/equipment-rest';

type filteredMain = {
  startDate: number;
  endDate: number;
  dashboardId: number;
  sensorId: number;
  timeInterval?: number;
  timeUnit?: string;
};

export interface IDatas {
  startDate: number;
  endDate: number;
  selectedSensor: string;
  intervalOption: string;
  aggregate?: string;
  export: string;
  timeInterval?: number;
  timeUnit?: string;
}

export interface IColorDatas {
  activeColor: string;
  inactiveColor: string;
  disconnectedColor: string;
}

export interface IOperatingSensors {
  dashboardId: number;
  operatingSensorId: number;
  ranges: { lower: string; upper: string }[];
  timeoutSeconds: number;
}

export const useDisplayType = (callback?: (data: displayType[]) => void) => {
  return useQuery([queryKeys.SENSOR, 'displayType'], getDisplayType, {
    onSuccess: callback && callback,
  });
};

export const useTagType = () => {
  return useQuery([queryKeys.SENSOR, 'tag-type'], getTagType);
};

export const useGetDevice = (
  setDeviceId?: React.Dispatch<SetStateAction<number>>,
) => {
  return useQuery([queryKeys.SENSOR, 'get-device'], getDevice, {
    onSuccess: (data: any) => {
      const findInit = data.find((item: any) => item.name === '미등록');
      setDeviceId && setDeviceId(findInit.id);
    },
  });
};

export const useFilteredDashboard = (prms: filteredMain) => {
  return useQuery(
    [queryKeys.SENSOR, 'main'],
    () => getFilteredDashboard(prms),
    {
      enabled: prms.sensorId !== -1,
    },
  );
};

export const useSensorAlarm = (prms: filteredMain, isRangeView?: boolean) => {
  return useQuery(
    [queryKeys.SENSOR, 'alarm', prms.sensorId],
    async () => {
      if (isRangeView) {
        try {
          const result = await getSensorsAlarm(prms);
          if (result?.success) {
            return result?.data;
          } else {
            return {};
          }
        } catch (error) {
          console.error('Error fetching sensor alarm:', error);
          return {};
        }
      } else {
        return {};
      }
    },
    {
      enabled: prms.sensorId !== -1,
    },
  );
};

export const usePatchSensor = () => {
  return useMutation((prms: changeSensorInfo) => putSensor(prms));
};

export const useDeleteSensor = () => {
  return useMutation((id: number) => deleteSensor(id));
};

export const usePostHideSensor = () => {
  return useMutation((prms: postHidePrms) => postHideSensor(prms));
};

export const usePatchOrderList = () => {
  return useMutation((prms: string) => patchDisplayOrders(prms));
};

export const useAggregateTypes = () => {
  return useQuery([queryKeys.SENSOR, 'aggregateType'], getAggregateTypes);
};

export const useTimeUnitTypes = () => {
  return useQuery([queryKeys.SENSOR, 'timeUnitType'], getTimeUnitTypes);
};

export const useAddSimpleSensor = () => {
  return useMutation((prms: FormData) => postAddSimpleSensor(prms));
};

export const useValidSimpleSensor = () => {
  return useMutation((prms: FormData) => postAddSimpleValidSensor(prms));
};
