import React from 'react';
import styled from 'styled-components';
import { receiveInfo } from 'types/alarm-T';
interface IProps {
  data: receiveInfo;
}
const AlarmManagerInfo: React.FC<IProps> = ({ data }) => {
  return (
    <Table>
      <tbody>
        <tr>
          <th>접수자</th>
          <td>{`${data.receiverName}(${data.receiverLoginId})`}</td>
        </tr>
        <tr>
          <th>접수일</th>
          <td>{data.receiveDate}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  position: relative;
  width: 650px;
  th {
    background: #6c6c6c;
    color: ${({ theme }) => theme.WHITE};
    width: 100px;
    height: 43px;
    vertical-align: middle;
  }
  tr {
    border-top: 1px solid #9a9a9a;
    border-bottom: 1px solid #9a9a9a;
  }
  td {
    vertical-align: middle;
    padding-left: 20px;
  }
`;

export default AlarmManagerInfo;
